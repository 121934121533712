@import "../../../../App.scss";
.EditUserMain {
  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 15px;
  }
  .errorText {
    color: red !important;
    font-size: 12px;
  }
  .SectionWrap {
    display: flex;
    @include tablet {
      display: unset;
    }
    .EditInfoSection {
      width: 100%;
      padding-right: 20px;
      padding-bottom: 20px;
      @include tablet {
        width: 100%;
        padding: unset;
        border: unset;
        padding-bottom: 20px;
      }
      .CarteOrgWarp {
        text-align: left;
        h6 {
          font-size: 14px;
          font-weight: 500;
          margin: 0;
          margin-bottom: 15px;
        }
        .uploadMain {
          label {
            width: 100%;
            .PhotoUpload {
              width: 100%;
              height: 170px;
              border: 1px solid $gray-color;
              border-radius: 5px;
              @include flex;
              .img1 {
                width: 100%;
                height: 100%;
                object-fit: contain;
                padding: 10px 0px;
              }
            }
          }
        }
        .InputWrap {
          display: flex;
          .FildBox {
            margin-top: 15px;
            width: 100%;
            p {
              font-size: 14px;
              font-weight: 500;
              margin: 0;
              margin-bottom: 10px;
            }
            input {
              border: none;
              width: 100%;
              border-bottom: 1px solid $input-border-color;
            }
            input:focus {
              outline: none;
            }
          }
          .FildBox:last-child {
            margin-left: 20px;
          }
        }
        .linkInputWrap {
          margin-top: 15px;
          width: 100%;
          p {
            font-size: 14px;
            font-weight: 500;
            margin: 0;
            margin-bottom: 10px;
          }
          input {
            border: none;
            width: 100%;
            border-bottom: 1px solid $input-border-color;
          }
          input:focus {
            outline: none;
          }
        }
        .changeRoleWrap {
          margin-top: 15px;
          width: 100%;
        }
        .changeBtnWrap {
          display: flex;
          justify-content: space-between;
          gap: 5px;
          select {
            border-radius: 5px;
            border: 2px solid #e9e9e9;
            background-color: #ffffff;
            padding: 5px;
            font-size: 14px;
            width: 40%;
          }
          option {
            font-weight: normal;
            display: block;
            min-height: 1.2em;
            padding: 0px 2px 1px;
            white-space: nowrap;
          }
          button {
            background-color: $pink-color;
            border: none;
            border-radius: 5px;
            color: $White-color;
            padding: 10px 50px;
          }
        }
      }
    }
    .OrgSection {
      width: 60%;
      padding-left: 20px;
      @include tablet {
        width: 100%;
        padding: unset;
      }
      h6 {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        margin-bottom: 15px;
        text-transform: capitalize;
      }
      .OrgContentWrap {
        border-radius: 5px;
        border: 1px solid #d2d2d2;
        height: 170px;
        overflow: auto;
        table {
          width: 100%;
        }
        tr {
          .LoaderWrap {
            text-align: center;
            border: unset;
            height: 20vh;
          }
          th {
            font-size: 14px;
            font-weight: 500;
            color: $th-color;
            padding: 5px 20px;
            pointer-events: none;
          }
          .normaltd {
            font-size: 14px;
            font-weight: 500;
            color: $th-color;
            padding: 5px 20px;
          }
          &:hover {
            background-color: $footer-color;
            td {
              color: $White-color;
              cursor: pointer;
            }
          }
          .selectedtd {
            background-color: $footer-color;
            font-size: 14px;
            font-weight: 500;
            color: $White-color;
            padding: 5px 20px;
          }
        }
      }
      .OrgBtnWrap {
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
        button {
          background-color: $footer-color;
          border: none;
          border-radius: 5px;
          color: $White-color;
          padding: 10px;
          margin-left: 10px;
          img {
            margin-left: 10px;
          }
        }
      }
      .linkInputWrap {
        margin-top: 15px;
        width: 100%;
        p {
          font-size: 14px;
          font-weight: 500;
          margin: 0;
          margin-bottom: 10px;
        }
        input {
          border: none;
          width: 60%;
          border-bottom: 1px solid $input-border-color;
        }
        input:focus {
          outline: none;
        }
      }
      .EditbtnWrap {
        margin-top: 25px;
        display: flex;
        justify-content: flex-end;
        button {
          background-color: $pink-color;
          border: none;
          border-radius: 5px;
          color: $White-color;
          padding: 10px 50px;
        }
      }
    }
  }
}
