@import "../../App.scss";
.main {
  position: relative;
  height: 100vh;
  width: 100%;
  .UserDetailsLayout {
    display: flex;
    height: calc(100% - 139px) !important;
    border: 1px solid #d2d2d2;
    overflow-x: auto;
    .removeOrg {
      cursor: pointer;
    }
    .PersonalDetails {
      width: 30%;

      @include tablet {
        width: 40%;
      }
      .basicInfo {
        border-bottom: 1px solid #d2d2d2;
        .UserImgLayout {
          width: 100%;
          padding: 20px;
          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
        .TextBold {
          padding: 0 30px;
          font-family: $Poppins-SemiBold;
          width: 100%;
          -ms-word-break: break-word;
          word-break: break-word;
          white-space: pre-wrap;
          h1 {
            font-size: 1.15rem;
            color: $footer-color;
            @include tabletNew {
              font-size: 20px;
            }
            @include desktopMed {
              font-size: 24px;
            }
          }
        }
        .NormalTextSmall {
          font-size: 14px;
          font-family: $Poppins-Regular;
          line-height: 21px;
          color: $userListing-color;
          padding: 10px 30px;
          @include tabletNew {
            font-size: 12px;
          }
          @include desktopMed {
            font-size: 13px;
          }
        }
        .EditIconLayout {
          padding: 30px 20px;
          display: flex;
          justify-content: flex-end;
          img {
            cursor: pointer;
          }
          @include tabletNew {
            padding: 20px 13px;
          }
          @include desktopMed {
            padding: 25px 17px;
          }
        }
      }
      .UserInfoLayout {
        padding: 20px 30px;
        border-bottom: none;
        .boldMedText {
          font-family: $Poppins-SemiBold;
          font-size: 14px;
          color: $userListing-color;
          @include tabletNew {
            font-size: 13px;
          }
        }
        p {
          font-size: 12px;
          color: $userListing-color;
          @include tabletNew {
            font-size: 11px;
          }
        }
        .UserInternalDetails {
          display: flex;
          .LeftUserContent {
            margin-right: 15px;
          }
        }
      }
    }

    .OtherDetails {
      width: 70%;
    }
    .QuickAndUserContainer {
      display: flex;
      border: 2px solid #d2d2d2;
      width: 100%;
      height: fit-content;
      .QuickOptionLayout {
        width: 30%;

        height: fit-content;

        padding: 30px;
        @include tabletNew {
          padding: 20px;
        }
        @include desktopMed {
          padding: 25px;
        }
        .ItalitBoldText {
          font-family: $Poppins-BoldItalic;
          font-size: 1.5rem;
          color: $footer-color;
          @include tabletNew {
            font-size: 16px;
          }
          @include desktopMed {
            font-size: 18px;
          }
        }
        .NormalTextSmall {
          font-size: 14px;
          font-family: $Poppins-Regular;
          color: $userListing-color;
          p {
            cursor: pointer;
          }
          @include tabletNew {
            font-size: 12px;
          }
          @include desktopMed {
            font-size: 13px;
          }
          .TextBlue {
            color: #3151b5;
            cursor: pointer;
            img {
              margin-left: 7px;
            }
          }
        }
      }
      .UserLogginLayout {
        border-left: 1px solid #d2d2d2;
        width: 70%;
        padding: 30px;
        @include tabletNew {
          padding: 20px;
        }
        @include desktopMed {
          padding: 25px;
        }
        .TextAndActionBtn {
          display: flex;
          justify-content: space-between;
          p {
            font-family: $Poppins-SemiBold;
            font-size: 16px;
            line-height: 25px;
            @include tabletNew {
              font-size: 14px;
            }
            @include desktopMed {
              font-size: 15px;
            }
          }
          button {
            background-color: $userListing-color;
            color: $White-color;
            border-radius: 5px;
            border: none;
            outline: none;
            padding: 1.5% 2.5%;
            font-family: $Poppins-Regular;
            font-size: 16px;
            @include tablet {
              padding: 0.5% 1%;
            }
            @include tabletNew {
              font-size: 14px;
              padding: 0.5% 1.5%;
            }
            @include desktopMed {
              font-size: 15px;
              padding: 0.75% 2%;
            }
          }
        }
        .LoginHistroyLayout {
          padding: 10px 0;
          .logginContentRow {
            display: flex;
            font-size: 14px;
            line-height: 21px;
            color: $userListing-color;
            p {
              flex: 1;
            }
            @include tabletNew {
              font-size: 12px;
            }
          }
        }
      }
    }
    .OrganisationDetailsContainer {
      padding: 20px;
      height: fit-content;
      border-bottom: 1px solid #d2d2d2;
      border-left: 1px solid #d2d2d2;
      .OrganisationAndSearchBar {
        display: flex;
        justify-content: space-between;
        p {
          font-size: 16px;
          line-height: 25px;
          font-weight: 600;
          color: $footer-color;
          margin: 0;
          span {
            opacity: 0.6;
          }
        }
        .SearchBar {
          img {
            margin-right: 5px;
          }
          input {
            outline: none;
            border: none;
            size: 12px;
          }
          input::placeholder {
            font-size: 12px;
          }
          input:focus {
            outline: none;
          }
        }
      }
      .OrgWrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        max-height: 200px;
        height: 100%;
        overflow-y: scroll;
        .OrganisationDetail {
          margin: 10px 0;
          min-width: 25%;

          .orgnisationLogo {
            height: 126px;
            width: 115px;
            img {
              height: 100%;
              width: 100%;
              object-fit: contain;
            }
          }
          p {
            margin: 10px 0;
            font-size: 16px;
            margin: 0;
            margin-bottom: 5px;
            @include tabletNew {
              font-size: 14px;
            }
          }
        }
        @media (min-width: 1280px) and (max-width: 1535px) {
          .OrganisationDetail {
            min-width: 33.33% !important;
          }
        }
        @include tabletNew {
          .OrganisationDetail {
            min-width: 50% !important;
          }
        }
        @media (min-width: 1200px) and (max-width: 1280px) {
          .OrganisationDetail {
            min-width: 40% !important;
          }
        }
      }
    }
    .ListingContainer {
      border: 1px solid #d2d2d2;
      border-bottom: none !important;
      padding: 30px 20px;

      .ListingHeaderAndSearchBar {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin-bottom: 2%;
        p {
          font-size: 16px;
          line-height: 25px;
          margin: 0;
          font-weight: 600;
          color: $footer-color;
          span {
            opacity: 0.6;
          }
          @include tabletNew {
            font-size: 12px;
          }
          @include desktopMed {
            font-size: 13px;
          }
        }
        .leftWrap {
          display: flex;
          align-items: center;
          .ChangeLayoutBtnWarp {
            .ChangeLayou {
              background-color: $White-color;
              border: none;
              border-radius: 2px;
              margin-right: 10px;
              img {
                width: 13px;
                height: 13px;
              }
            }
            .activeBtn {
              background-color: $footer-color;
              border: none;
              border-radius: 2px;
              margin-right: 10px;
              img {
                width: 13px;
                height: 13px;
              }
            }
          }
          .SearchBar {
            img {
              margin-right: 5px;
            }
            input {
              outline: none;
              border: none;
              size: 12px;
              overflow: hidden;
            }
            input::placeholder {
              font-size: 10px;
            }
            removeOrg input:focus {
              outline: none;
            }
          }
        }
      }
      .InvisibleCard {
        opacity: 40%;
      }
      .CardListingLayout {
        display: flex;
        width: 100%;
        padding: 1% 0;
        max-height: 200px;
        flex-wrap: wrap;
        overflow-y: scroll;

        .ListingContent {
          width: 47%;
          display: flex;
          margin: 1% 1%;
          justify-content: space-between;
          padding: 2.5%;
          background-color: #f1f1f1;
          .LogoAndDetails {
            display: flex;
            .ListingLogo {
              height: 128px;
              width: 130px;
              object-fit: cover;
              @include tabletNew {
                height: 78px;
                width: 80px;
              }
              @include desktopMed {
                height: 98px;
                width: 100px;
              }
              img {
                box-shadow: 3px 3px 10px #00000029;
                height: 100%;
                width: 100%;
              }
            }

            .ListingDetail {
              font-size: 14px;
              color: $userListing-color;
              margin-left: 10px;
              @include tabletNew {
                font-size: 12px;
              }
              @include desktopMed {
                font-size: 13px;
              }
              p {
                margin: 0;
                margin: 3% 0;
              }
            }
          }

          .actionOnListing {
            display: flex;
            flex-direction: column;
            img {
              width: 20px;
              height: 20px;
              cursor: pointer;
            }
            svg {
              font-size: 20px;
              cursor: pointer;
            }
          }
        }
      }
      .TableListngLayOut {
        width: 100%;
        padding: 1% 0;
        max-height: 200px;
        flex-wrap: wrap;
        overflow-y: scroll;
        table {
          width: 100%;
          min-width: 700px;
        }
        tr {
          th {
            padding: 20px 0px;
            color: $userListing-color;
            font-size: 14px;
            font-weight: 400;
            border-bottom: 2px solid #e6eaf0;
          }
          td {
            padding: 20px 0;
            color: $userListing-color;
            font-size: 14px;
            font-weight: 400;
            color: $userListing-color;
            border-bottom: 2px solid #e6eaf0;
            .ListingIcons {
              display: flex;
              justify-content: space-around;
              align-items: center;
              svg {
                cursor: pointer;
                font-size: 18px;
              }
              img {
                cursor: pointer;
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }
    }
  }
}
