@import "../../../App.scss";

.CreateCredit {
  h3 {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 25px;
  }

  .errorText {
    font-weight: 500 !important;
    color: red !important;
    font-size: 12px !important;
  }

  .AllItemsWrap {
    .ExistingItemsWrap {
      margin-bottom: 20px;

      p {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 7px;
      }

      .ItemWrap {
        display: grid;
        grid-column: 30% 30% fr;
        grid-template-columns: 40% 40% 20%;

        label {
          color: $gray-color;
        }

        .price {
          input {
            width: 70%;
          }
        }

        .radioCustom {
          opacity: 0;
          position: absolute;
        }

        .radioCustom,
        .radioCustomLabel {
          display: inline-block;
          vertical-align: middle;
          cursor: pointer;
        }

        .radioCustomLabel {
          position: relative;
        }

        .radioCustom+.radioCustomLabel:before {
          content: "";
          background: #fff;
          border: 1px solid $gray-color;
          display: inline-block;
          width: 6px;
          height: 6px;
          padding: 5px;
          border-radius: 2px;
          text-align: center;
          margin-right: 10px;
        }

        .radioCustom:checked+.radioCustomLabel:before {
          background: $pink-color;
        }
      }

      .InputWrap {
        border-bottom: 1px solid $input-border-color;
        display: flex;
        max-width: 100px;

        p {
          padding-top: 7px;
        }

        input {
          background-color: $transparent-color;
          border: none;
          width: 100%;
          color: $th-color;
        }

        input:focus {
          outline: none;
        }
      }

      .ExtraInputWrap {
        border-bottom: 1px solid $input-border-color;
        display: flex;
        max-width: 240px;

        input {
          background-color: $transparent-color;
          border: none;
          width: 100%;
          color: $th-color;
        }

        input:focus {
          outline: none;
        }
      }
    }

    .NewItemsWrap {
      margin-bottom: 20px;

      p {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 7px;
      }

      .plus {
        cursor: pointer;
      }

      .ItemWrap {
        display: grid;
        grid-column: 30% 30% fr;
        grid-template-columns: 40% 40% 20%;

        label {
          color: $gray-color;
        }

        .price {
          input {
            width: 70%;
          }
        }

        .radioCustom {
          opacity: 0;
          position: absolute;
        }

        .radioCustom,
        .radioCustomLabel {
          display: inline-block;
          vertical-align: middle;
          cursor: pointer;
        }

        .radioCustomLabel {
          position: relative;
        }

        .radioCustom+.radioCustomLabel:before {
          content: "";
          background: #fff;
          border: 1px solid $gray-color;
          display: inline-block;
          width: 6px;
          height: 6px;
          padding: 5px;
          border-radius: 2px;
          text-align: center;
          margin-right: 10px;
        }

        .radioCustom:checked+.radioCustomLabel:before {
          background: $pink-color;
        }
      }

      .InputWrap {
        border-bottom: 1px solid $input-border-color;
        display: flex;
        max-width: 100px;

        p {
          padding-top: 7px;
        }

        input {
          background-color: $transparent-color;
          border: none;
          width: 100%;
          color: $th-color;
        }

        input:focus {
          outline: none;
        }
      }

      .ExtraInputWrap {
        border-bottom: 1px solid $input-border-color;
        display: flex;
        max-width: 240px;

        input {
          background-color: $transparent-color;
          border: none;
          width: 100%;
          color: $th-color;
        }

        input:focus {
          outline: none;
        }
      }
    }

    .FlexWrap {
      display: flex;

      .InputBox {
        width: 100%;
        margin-bottom: 30px;
        margin-right: 10px;

        p {
          font-size: 14px;
          font-weight: 600;
          margin: 0;
          margin-bottom: 7px;
        }

        &:last-child {
          margin-right: unset;
        }

        input {
          background-color: $transparent-color;
          border: none;
          width: 100%;
          color: $th-color;
          border-bottom: 2px solid $input-border-color;
        }

        input:focus {
          outline: none;
        }
      }
    }
  }

  .btnWrap {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;

    button {
      background-color: $pink-color;
      border: none;
      border-radius: 5px;
      color: $White-color;
      padding: 10px;
      min-width: 100px;
    }
  }
}