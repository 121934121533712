@import "../../../App.scss";
.ViewOrgSubscriptionPlanMain {
  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 15px;
  }
  .ViewOrgSubscriptionPlanWrap {
    max-height: 350px;
    min-height: 350px;
    border-radius: 5px;
    border: 1px solid #d2d2d2;
    overflow: auto;
    table {
      width: 100%;
    }
    tr {
      th,
      td {
        font-size: 14px;
        font-weight: 500;
        color: $th-color;
        padding: 5px 20px;
      }
      th {
        pointer-events: none;
      }

      .selectStyle {
        background-color: $footer-color;
        font-size: 14px;
        font-weight: 500;
        color: $White-color;
        cursor: pointer;
        padding: 5px 20px;
      }
      .activeIcon {
        padding-left: 35px;
      }
    }
    .actionContainer {
      display: flex;
      .stopButton {
        padding: 5px 8px !important;
        margin-right: 10px;
      }
      .editButton {
        padding: 5px 8px !important;
      }
      button {
        background-color: $pink-color;
        border: none;
        border-radius: 5px;
        color: $White-color;
        // padding: 5px 8px !important;
      }
    }
    .NoData {
      pointer-events: none;
      .LoaderWrap {
        text-align: center;
        border: unset;
        height: 50vh;
      }
    }
  }

  .AddUseAsSection {
    display: flex;
    align-items: center;
    margin-top: 15px;
    button {
      background-color: $pink-color;
      border: none;
      border-radius: 5px;
      color: $White-color;
      padding: 10px;
      margin-right: 10px;
    }
  }
}
.ModaleSize {
  max-width: 90vw !important;
  @include tablet {
    max-width: 70vw !important;
  }
}
