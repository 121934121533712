@import "../../../../App.scss";
.EditEmailMain {
	h3 {
		text-align: center;
		font-size: 18px;
		font-weight: 400px;
		margin: 0;
		margin-bottom: 15px;
	}
	.EditEmailWrap {
		.InputWrap {
			margin-top: 15px;
			p {
				font-size: 14px;
				font-weight: 500;
				margin: 0;
				margin-bottom: 10px;
			}
			input {
				border: none;
				width: 100%;
				border-bottom: 1px solid $input-border-color;
			}
			input:focus {
				outline: none;
			}
			select {
				margin-top: 10px;
				margin-bottom: 20px;
				border: none;
				width: 100%;
				border-bottom: 1px solid $input-border-color;
			}
			select:focus {
				outline: none;
			}
		}
        .btnWrap {
			margin-top: 15px;
			display: flex;
			justify-content: flex-end;
			button {
				background-color: $pink-color;
				border: none;
				border-radius: 5px;
				color: $White-color;
				padding: 10px;
			}
		}
	}
}
