@import "../../App.scss";
.main_container {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  background-image: url("../../assets/images/loginBackgroundImage.svg");
  justify-content: center;
  align-items: center;

  .form_container {
    max-width: 400px;
    width: 100%;
    border-radius: 15px;
    background-color: $White-color;
    margin: 20px;
    padding: 30px;
    .errorText{
      color:red !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      }
    .img_content {
      width: 198px;
      height: 100px;
    }

    .input_content {
      h4{
        text-align: center;
        font-size: 22px;
        font-weight: 600;
        margin: 0;
        margin-top: 10px;
      }
      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
      .input_field {
        position: relative;
        margin-top: 20px;
        p {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
        }
        input {
          border: none;
          width: 100%;
          border-bottom: 1px solid #777777;
          margin-top: 18px;
          padding-right: 40px;
        }
        .eyeIcon {
          cursor: pointer;
          position: absolute;
          top: 54%;
          right: 4px;
          width: 31.5px;
          height: 25.2px;
        }
        
      }
      .no_outline {
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
      }
      .no_outline:focus {
        outline: none;
      }
      .checkboxBtn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        @media (max-width: 400px) {
          display: unset;
          margin-top: 20px;
        }
        .rememberme_checkbox {
          @media (max-width: 400px) {
            margin-top: 16px;
          }
          .remember_me_checkbox {
            background: $pink-color;
          }
          input {
            padding: 0;
            height: initial;
            width: initial;
            margin-bottom: 0;
            display: none;
            cursor: pointer;
            &:checked ~ .check::after {
              content: "";
              display: block;
              position: absolute;
              top: 2px;
              left: 8px;
              width: 5px;
              height: 14px;
              border: solid $pink-color;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }
          }
          .check {
            position: relative;
            cursor: pointer;
            font-size: 14px;
            color: inherit;
            margin-top: 0;

            &::before {
              content: "";
              -webkit-appearance: none;
              background-color: transparent;
              border: 1px solid $gray-color;
              padding: 10px;
              border-radius: 5px;
              display: inline-block;
              position: relative;
              vertical-align: middle;
              cursor: pointer;
              margin-right: 5px;
            }
          }
          span {
            font-size: 14px;
            font-weight: 500;
          }
        }
        .loginBtn {
          .loginButton {
            width: 115px;
            height: 45px;
            border-radius: 5px;
            background: $pink-color;
            border: none;
            color: $White-color;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            @media (max-width: 400px) {
              width: 100%;
              margin-top: 10px;
            }
            .loginBtnText {
              margin: 0;
            }
            img {
              height: 13px;
              width: 16px;
              margin-left: 10px;
            }
          }
        }
        
      }
    }
  }
}
