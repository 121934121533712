@import "../../App.scss";
.main {
  height: 100vh;
  position: relative;
  .MainLayoutOrg {
    display: flex;
    height: calc(100vh - 138px) !important;
    border: 1px solid #d2d2d2;
    overflow-x: auto;
    h2 {
      font-size: 22px;
      line-height: 21px;
      color: $userListing-color;
    }
    .InvisibleCard {
      opacity: 40% !important;
    }
    .OrgDetails {
      width: 30%;
      box-sizing: content-box;
      .basicOrgDetails {
        border-right: 1px solid #d2d2d2;
        padding: 4% 6%;
        height: fit-content;
        .OrgStatusWrap {
          padding-bottom: 20px;
          .StatusWrap {
            padding: 5px 0px;
            border-bottom: 1px solid #d2d2d2;
            display: flex;
            justify-content: space-between;
            p {
              color: #595f72;
              font-size: 14px;
              margin: 0;
            }
            &:last-child {
              border-bottom: unset;
            }
          }
        }
        .orgLogo {
          max-height: 330px;
          max-width: 330px;
          margin: 0 auto;
          margin-bottom: 20px;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
          @include tabletNew {
            width: 150px;
            height: 150px;
          }
          @include desktopMed {
            width: 170px;
            height: 170px;
          }
          @include desktoplarge {
            width: 220px;
            height: 220px;
          }
        }
        .BannerImg {
          width: 100%;
          height: 100px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      .OrganisationInfo {
        border-top: 1px solid #d2d2d2;
        border-right: 1px solid #d2d2d2;
        border-bottom: none;
        padding: 4% 6%;
        padding-bottom: 10%;
        height: fit-content;

        .semiBoldText {
          font-size: 14px;
          font-family: $Poppins-SemiBold;
          line-height: 21px;
          color: $userListing-color;
          margin: 0;
          margin-bottom: 3%;
          @include tabletNew {
            font-size: 12px;
          }
          @include desktopMed {
            font-size: 13px;
          }
        }
        .OrgAndMembership {
          display: flex;
          justify-content: space-between;
          margin-bottom: 3%;
        }
        p {
          margin: 0;
          font-size: 14px;
          line-height: 21px;
          color: $userListing-color;
          @include tabletNew {
            font-size: 11px;
          }
          @include desktopMed {
            font-size: 12px;
          }
        }
      }

      .orgName {
        margin: 3% 0;
        margin-top: 5%;
        font-family: $Poppins-SemiBold;
        font-size: 34px;
        line-height: 51px;
        color: $footer-color;
        -ms-word-break: break-word;
        word-break: break-word;
        white-space: pre-wrap;
        @include tabletNew {
          font-size: 20px;
        }
        @include desktopMed {
          font-size: 22px;
        }
        @include desktoplarge {
          font-size: 28px;
        }
      }
      .OtherDetails {
        p {
          margin: 0;
          font-size: $Poppins-Regular;
          font-size: 14px;
          color: $userListing-color;
          @include tabletNew {
            font-size: 12px;
          }
          @include desktopMed {
            font-size: 13px;
          }
        }
        .email {
          margin-bottom: 2%;
        }
        .SomeSpacing {
          margin-bottom: 2%;
        }
        .ItalicText {
          font-family: $Poppins-Italic;
          font-weight: 400 !important;
        }
        .Flex {
          display: flex;
          justify-content: space-between;
        }
        .EditIcon {
          height: 20px;
          width: 22px;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
    .QuickOptAndInvoiceContainer {
      display: flex;
      width: 100%;
      height: fit-content;
      .QuickOptionContainer {
        height: fit-content;
        border-bottom: 1px solid #d2d2d2;
        border-right: 1px solid #d2d2d2;
        padding: 3% 3% 7% 3%;
        width: 35%;
        .BoldMedText {
          font-family: $Poppins-BoldItalic;
          font-size: 24px;
          line-height: 35px;
          color: $footer-color;
          @include tabletNew {
            font-size: 16px;
          }
          @include desktopMed {
            font-size: 18px;
          }
        }
        .SimpleGreyText {
          font-size: 14px;
          line-height: 21px;
          color: $userListing-color;
          cursor: pointer;
          @include tabletNew {
            font-size: 12px;
          }
          @include desktopMed {
            font-size: 13px;
          }
        }
      }
      .WillBeAddedLater {
        width: 65%;
        background-color: rgba($color: #000000, $alpha: 0.6);
        @include flex;
        h1 {
          color: $White-color;
        }
      }
    }
    .UsersAndOrgBranches {
      display: flex;
      .UsersContainer {
        width: 50%;
        border-right: 1px solid #d2d2d2;
        padding: 3%;

        .HeaderAndSearchBar {
          display: flex;
          margin-bottom: 10px;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          p {
            font-size: 16px;
            line-height: 25px;
            font-weight: 600;
            margin: 0;
            width: 30%;
            color: $footer-color;
            @include tabletNew {
              font-size: 12px;
            }
            @include desktopMed {
              font-size: 13px;
            }

            span {
              opacity: 0.6;
            }
          }
          .SearchBar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 70%;
            img {
              margin-right: 5px;
            }
            input {
              outline: none;
              border: none;
              overflow: hidden;
              size: 12px;
            }
            input::placeholder {
              font-size: 10px;
            }
            input:focus {
              outline: none;
            }
          }
        }
        .UsersDetails {
          max-height: 120px;
          overflow-y: scroll;
          table {
            width: 100%;
          }
          tr {
            border-bottom: 2px solid;
            border-bottom-color: rgba(210, 210, 210, 0.2);
            padding: 2% 0;
            th {
              font-size: 14px;
              line-height: 21px;
              color: $userListing-color;
              font-weight: bold;
              @include tabletNew {
                font-size: 12px;
              }
              @include desktopMed {
                font-size: 13px;
              }
            }
            td {
              padding: 5px 0px;
              font-size: 14px;
              line-height: 21px;
              color: $userListing-color;
              font-weight: 200;
              @include tabletNew {
                font-size: 12px;
              }
              @include desktopMed {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
  .OrganisatonBranchesContainer {
    width: 50%;
    padding: 3%;
    background-color: rgba($color: #000000, $alpha: 0.6);
    @include flex;
    h1 {
      color: $White-color;
    }
    .HeaderOrgBranches {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 16px;
        margin: 0;
        line-height: 25px;
        font-weight: 600;
        color: $footer-color;
        @include tabletNew {
          font-size: 12px;
        }
        @include desktopMed {
          font-size: 13px;
        }
        span {
          opacity: 0.6;
        }
      }
      .UploadIconLayout {
        height: 20px;
        width: 22px;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    .MainAndSub {
      display: flex;
      width: 100%;
      .MainBranch {
        width: 50%;
        .TextBoldSemi {
          font-size: 14px;
          font-weight: bold;
          line-height: 21px;
          color: $userListing-color;
          margin-top: 3%;
          margin-bottom: 2%;
          @include tabletNew {
            font-size: 12px;
          }
          @include desktopMed {
            font-size: 13px;
          }
        }
        .MainBranchLayout {
          border: 1px solid #df1681;
          padding: 3% 1%;
          border-radius: 40px;
          text-align: center;
          color: $userListing-color;
          font-size: 14px;
          @include tabletNew {
            font-size: 10px;
            padding: 3%;
          }
          @include desktopMed {
            font-size: 13px;
          }
        }
      }
      .SubBranch {
        width: 50%;
        padding-left: 5%;
        .TextBoldSemi {
          font-size: 14px;
          font-weight: bold;
          line-height: 21px;
          color: $userListing-color;
          margin-top: 3%;
          margin-bottom: 4%;
          @include tabletNew {
            font-size: 12px;
          }
          @include desktopMed {
            font-size: 13px;
          }
        }
        .SubBranchContainer {
          height: 250px;
          overflow-y: scroll;
          .SubBranchContent {
            border: 1px solid #595f72;

            padding: 3% 1%;
            margin-bottom: 5%;
            border-radius: 40px;
            text-align: center;
            color: $userListing-color;
            font-size: 14px;
            @include tabletNew {
              font-size: 10px;
              padding: 3%;
            }
            @include desktopMed {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
.ListingContainer {
  border: 1px solid #d2d2d2;
  border-bottom: none !important;
  padding: 30px 20px;

  .ListingHeaderAndSearchBar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 2%;
    p {
      font-size: 16px;
      line-height: 25px;
      margin: 0;
      font-weight: 600;
      color: $footer-color;
      span {
        opacity: 0.6;
      }
      @include tabletNew {
        font-size: 12px;
      }
      @include desktopMed {
        font-size: 13px;
      }
    }
    .leftWrap {
      display: flex;
      align-items: center;
      .ChangeLayoutBtnWarp {
        .ChangeLayou {
          background-color: $White-color;
          border: none;
          border-radius: 2px;
          margin-right: 10px;
          img {
            width: 13px;
            height: 13px;
          }
        }
        .activeBtn {
          background-color: $footer-color;
          border: none;
          border-radius: 2px;
          margin-right: 10px;
          img {
            width: 13px;
            height: 13px;
          }
        }
      }
      .SearchBar {
        img {
          margin-right: 5px;
        }
        input {
          outline: none;
          border: none;
          size: 12px;
          overflow: hidden;
        }
        input::placeholder {
          font-size: 10px;
        }
        input:focus {
          outline: none;
        }
      }
    }
  }
  .ListingLayout {
    display: flex;
    width: 100%;
    padding: 1% 0;
    max-height: 200px;
    flex-wrap: wrap;
    overflow-y: scroll;

    .ListingContent {
      width: 47%;
      display: flex;
      margin: 1% 1%;
      justify-content: space-between;
      padding: 2.5%;
      background-color: #f1f1f1;
      .LogoAndDetails {
        display: flex;
        .ListingLogo {
          height: 128px;
          width: 130px;
          object-fit: cover;
          @include tabletNew {
            height: 78px;
            width: 80px;
          }
          @include desktopMed {
            height: 98px;
            width: 100px;
          }
          img {
            box-shadow: 3px 3px 10px #00000029;
            height: 100%;
            width: 100%;
          }
        }
        .ListingDetail {
          font-size: 14px;
          color: $userListing-color;
          margin-left: 10px;
          @include tabletNew {
            font-size: 12px;
          }
          @include desktopMed {
            font-size: 13px;
          }
          p {
            margin: 0;
            margin: 3% 0;
          }
        }
      }

      .actionOnListing {
        display: flex;
        flex-direction: column;
        img {
          width: 20px;
          height: 20px;
        }
        svg {
          font-size: 20px;
        }
      }
    }
  }
  .TableListngLayOut {
    width: 100%;
    padding: 1% 0;
    max-height: 200px;
    flex-wrap: wrap;
    overflow-y: scroll;
    table {
      width: 100%;
    }
    tr {
      th {
        padding: 20px 0px;
        color: $userListing-color;
        font-size: 14px;
        font-weight: 400;
        border-bottom: 2px solid #e6eaf0;
      }
      td {
        padding: 20px 0;
        color: $userListing-color;
        font-size: 14px;
        font-weight: 400;
        color: $userListing-color;
        border-bottom: 2px solid #e6eaf0;
        .ListingIcons {
          display: flex;
          justify-content: space-around;
          align-items: center;
          svg {
            cursor: pointer;
            font-size: 18px;
          }
          img {
            cursor: pointer;
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}

.ModaleSize {
  max-width: 90vw !important;
  @include tablet {
    max-width: 70vw !important;
  }
}
