@import "../../../App.scss";
.EditEmailMain {
  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: 400px;
    margin: 0;
    margin-bottom: 15px;
  }
  .EditEmailWrap {
    .InputWrap {
      margin-top: 15px;
      .to {
        color: #df1681;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        margin:0;
      }
      input {
        width: 100%;
        border: 1px solid #e8e8e8;
        border-radius: 10px;
        padding: 5px 10px;
      }
      input:focus {
        outline: none;
      }
      select {
        margin-top: 10px;
        margin-bottom: 20px;
        border: none;
        width: 100%;
        border-bottom: 1px solid $input-border-color;
      }
      select:focus {
        outline: none;
      }
    }
    
    .btnWrap {
      margin-top: 15px;
      display: flex;
      gap: 1rem;
      button {
        background-color: $pink-color;
        border: none;
        border-radius: 5px;
        color: $White-color;
        padding: 10px;
      }
      .cancelBtn {
        background-color: $White-color;
        color: #df1681;
        border: 1px solid #df1681;
        padding: 10px;
        border-radius: 5px;
      }
    }
  }
}
