@import "../../../App.scss";
.MainLayout {
  display: flex;
  .SideBarMain {
    width: 100%;
    max-width: 300px;
    height: 100vh;
    overflow: hidden;
    padding-right: 21px;
    box-shadow: 0px 3px 6px #00000029;
    overflow: scroll;
    @include laptopscreen {
      height: calc(100vh - 60px);
      overflow-y: auto;
    }
    @include tablet {
      display: none;
    }
  }
  .LayoutSection {
    flex: 1;
    min-width: 70px;
    width: 100%;
  }
}
