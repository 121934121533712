@import "../../App.scss";
.EmailMain {
	position: relative;
	height: 100vh;
	width: 100%;
	.EmailListingSection {
		width: 100%;
		height: calc(100% - 233px);
		padding: 0px 20px;
		overflow-x: auto;
		table {
			width: 100%;
			@media screen and (max-width: 1024px) {
				width: 950px;
			}
		}
		tr {
			.LoaderWrap {
				text-align: center;
				border: unset;
				height: 50vh;
			}
			th {
				padding: 20px 0px;
				color: $userListing-color;
				font-size: 14px;
				font-weight: 600;
				border-bottom: 2px solid #e6eaf0;
			}
			td {
				padding: 20px 0;
				color: $userListing-color;
				font-size: 14px;
				font-weight: 400;
				border-bottom: 2px solid #e6eaf0;
				button {
					background-color: $footer-color;
					border: 0;
					color: $White-color;
					border-radius: 5px;
					padding: 5px 20px;
				}
			}
		}
	}
}
