@import "../../../App.scss";
.SubscriptionOrgPage {
  background: $div-bg-color;
  height: calc(100% - 200px);
  overflow: scroll;
  padding: 30px;
  @include laptopscreen {
    padding: 20px;
  }
  .LoaderWrap {
    text-align: center;
    border: unset;
    height: 50vh;
    border: none;
  }
  .pinkText {
    color: $pink-color;
  }
  p {
    font-weight: bold;
    font-size: 16px;
    color: $userListing-color;
  }
  button {
    background-color: $userListing-color;
    border: none;
    border-radius: 5px;
    color: $White-color;
    padding: 5px 8px !important;
  }
  .tableContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    .SearchBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      img {
        margin-right: 5px;
      }
      span {
        color: $userListing-color;
        font-size: 12px;
      }
      input {
        outline: none;
        border: none;
        overflow: hidden;
        size: 12px;
      }
      input::placeholder {
        font-size: 10px;
      }
      input:focus {
        outline: none;
      }
      .activeOnly {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
    .Switch {
      color: $userListing-color;
      position: relative;
      display: inline-block;
      width: 30px;
      height: 15px;
    }
    .Switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .Slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $userListing-color;
      transition: 0.4s;
      border-radius: 34px;

      &::before {
        position: absolute;
        content: "";
        height: 12px;
        width: 12px;
        left: 3px;
        top: 2px;
        background-color: $White-color;
        transition: 0.4s;
        border-radius: 50%;
      }
    }
    input:checked + .Slider {
      background-color: $pink-color;
    }
    input:checked + .Slider:before {
      transform: translateX(26px);
      background-color: $White-color;
      left: -10px;
    }
    .TableListngLayOut {
      background: $White-color;
      min-height: 300px;
      max-height: 300px;
      flex-wrap: wrap;
      overflow-y: scroll;
      padding: 10px 25px 20px 25px;
      border-radius: 5px;
      .EditIcon {
        height: 20px;
        width: 22px;
        img {
          height: 100%;
          width: 100%;
        }
      }
      table {
        width: 100%;
      }
      tr {
        th {
          padding: 20px 0px 0px 0px;
          color: $userListing-color;
          font-size: 14px;
          font-weight: bold;
          border-bottom: 2px solid #e6eaf0;
        }
        th:first-child {
          min-width: 190px;
        }
        th {
          min-width: 100px;
        }
        td {
          padding: 5px 0px 7px 0px;
          color: $userListing-color;
          font-size: 14px;
          font-weight: 400;
          color: $userListing-color;
          border-bottom: 2px solid #e6eaf0;
        }
        .ListingIcons {
          display: flex;
          justify-content: space-around;
          align-items: center;
          svg {
            color: $userListing-color;
            cursor: pointer;
            font-size: 18px;
          }
          img {
            cursor: pointer;
            width: 18px;
            height: 18px;
          }
        }
        .actionContainer {
          display: flex;
          svg {
            font-size: 18px;
            margin-right: 10px;
          }
          button:nth-child(odd) {
            margin-right: 10px;
          }
        }
      }
    }
    .AddUseAsSection {
      display: flex;
      justify-content: end;
      padding: 10px;
      padding-right: 0 !important;
    }
  }
  button {
    align-self: flex-end;
  }
}
.ModaleSize {
  max-width: 90vw !important;
  @include tablet {
    max-width: 70vw !important;
  }
}
