@import "../../../../App.scss";
.AddUserToOrgMain {
  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 15px;
  }
  .SearchBranch {
    margin-bottom: 20px;
    input {
      border: 2px solid $fild-border-color;
      border-radius: 5px;
      padding: 5px 10px;
    }
    input:focus {
      outline: none;
    }
    button {
      background-color: $footer-color;
      border: none;
      border-radius: 5px;
      color: $White-color;
      padding: 8px 10px;
      margin-left: 10px;
      img {
        margin-left: 10px;
        width: 14px;
      }
    }
  }
  .AddUserToOrgWrap {
    border-radius: 5px;
    border: 1px solid #d2d2d2;
    overflow: auto;
    table {
      width: 100%;
    }
    tr {
      th {
        font-size: 14px;
        font-weight: 500;
        color: $th-color;
        padding: 5px 20px;
        pointer-events: none;
      }
      .defaultStyle {
        font-size: 14px;
        font-weight: 500;
        color: $th-color;
        padding: 5px 20px;
      }
      td {
        max-width: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:hover {
        background-color: $footer-color;
        td {
          color: $White-color;
          cursor: pointer;
        }
      }
      .selectStyle {
        width: 50px;
        background-color: $footer-color;
        font-size: 14px;
        font-weight: 500;
        color: $White-color;
        cursor: pointer;
        padding: 5px 20px;
      }
    }
  }
  .PaginationWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
    p {
      margin: 0;
      cursor: pointer;
      margin-right: 10px;
      font-size: 14px;
      font-weight: 500;
      color: $pink-color;
    }
  }
  .AddUseAsSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    .AddUseAsWrap {
      p {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        margin-bottom: 10px;
      }
      input {
        border: none;
        width: 100%; // width: 60%;
        border-bottom: 1px solid $input-border-color;
      }
      input:focus {
        outline: none;
      }
    }
    button {
      background-color: $pink-color;
      border: none;
      border-radius: 5px;
      color: $White-color;
      padding: 10px 50px;
    }
  }
}
