@import "../../../App.scss";

.priceTableFormSection {
  min-height: 100%;
  width: 100% !important;

  .error {
    text-align: left;
    color: red;
    font-size: 12px;
  }

  p {
    font-weight: 500;
  }

  .priceLineTitle {
    margin-top: 20px;
  }

  .startImageWrapper {
    width: 40px;
    height: 40px;
    background-color: #fff;
    padding: 8px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  }

  .inputsWrapper {
    display: flex;
    flex-direction: column;

    .cityInput {
      border: 1px solid $black-color;
    }

    .numberPart {
      max-width: 350px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      div {
        width: 45%;

        input {
          width: 100%;
        }
      }
    }

    input,
    select {
      border: $input-border-color;
      outline: none;
      padding: 10px 10px;

      border-radius: 5px;
    }

    label {
      margin: 15px 0;
      font-weight: 700;
    }
  }

  .inputsWrapperLine {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 5px;

    .cityInput {
      border: 1px solid $black-color;
    }

    .numberPart {
      max-width: 350px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      div {
        width: 45%;

        input {
          width: 100%;
        }
      }
    }

    input,
    select {
      border: $input-border-color;
      outline: none;
      padding: 10px 10px;

      border-radius: 5px;
    }

    label {
      margin: 15px 0;
      font-weight: 700;
    }
  }

  .btnwrapper {
    display: flex;
    gap: 12px;
    justify-content: end;
    margin-top: 30px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 8px 18px;
      border: none;
      color: #fff;
      border-radius: 4px;

      img {
        // margin-right: 8px;
      }
    }

    .btncancel {
      background-color: #595f72;
    }

    .btnadd {
      background-color: #777777;
    }
  }
}