@import "../../../App.scss";
.FooterSection {
	background-color: $userListing-color;
	padding: 8px 0px;
	position: fixed;
	bottom: 0;
	max-height: 57px;
	height: 100%;
	width: 100%;
	.PaginationWrap {
		display: flex;
		align-items: center;
		position: fixed;
		right: 25px;
		p {
			color: $White-color;
			margin: 0;
			margin-right: 15px;
		}
		.PaginationBtnWrap {
			display: flex;
			img {
				margin-left: 10px;
				cursor: pointer;
			}
		}
	}
}
