@import "../../../App.scss";
.contactformsection {
  h3 {
    margin-left: 24px;
  }
  span {
    font-weight: 500 !important;
    color: red !important;
    font-size: 12px !important;
  }
  .contactformwrapper {
    padding: 0 24px;

    display: grid;
    grid-template-columns: 2fr 1.5fr;
    grid-template-rows: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 0px;

    .col1 {
      .addressinputwrapper {
        .startImageWrapper {
          width: 40px;
          height: 40px;
          background-color: #fff;
          padding: 8px 12px;
          border-radius: 4px;
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
        }

        .startcheckbox {
          width: 30px;
          height: 30px;
          background: #fff;
          padding: 24px;
          border-radius: 5px;
        }

        display: flex;
        flex-direction: column;
        input,
        select {
          border: $input-border-color;

          outline: none;
          padding: 10px 10px;

          border-radius: 5px;
        }
        input[type="checkbox"] {
          width: 30px;

          height: 30px;
        }
        label {
          margin: 15px 0;
          font-weight: 700;
        }
      }
      .uploadPicWrapper {
        display: flex;
        gap: 12px;

        .imageprview {
          .preview {
            position: relative;
            @include flex;
            width: 200px;
            height: 200px;
            background-color: #fff;
            // border: 8px solid #fff;
            border-radius: 5px;
            .innerpreview {
              p {
                font-weight: 700;
              }
              border-radius: 5px;
              @include flex;
              width: 190px;
              height: 190px;
              background-color: $div-bg-color;
            }
          }
          .cross {
            position: absolute;
            top: 12px;
            right: 12px;

            img {
              width: 23px;
              color: #fff;
            }
          }
        }

        .upload {
          display: flex;
          gap: 12px;
          align-items: end;
          .uploadImg {
            @include flex;
            width: 50px;
            height: 50px;
            background: #fff;
            border-radius: 5px;
            padding: 12px;
            img {
              width: 24px;
            }
          }
        }
      }
    }

    .col2 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .switchWrapperandbtn {
        display: flex;
        flex-direction: column;

        .Switch {
          position: relative;
          display: inline-block;
          width: 40px;
          height: 20px;
        }
        .Switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }
        .Slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #d1d1d1;
          transition: 0.4s;
          border-radius: 34px;

          &::before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            left: 3px;
            top: 2px;
            background-color: $White-color;
            transition: 0.4s;
            border-radius: 50%;
          }
        }
        input:checked + .Slider {
          background-color: blue;
          background-color: $pink-color;
        }
        input:checked + .Slider:before {
          transform: translateX(26px);
          background-color: $White-color;
          left: -4px;
        }

        label {
          margin: 10px 0;
          font-weight: 700;
        }

        .sendpasswordreset {
          margin-top: 12px;
          background-color: #595f72;
          padding: 12px 18px;
          border: none;
          color: #fff;
          border-radius: 4px;
        }
      }
    }
  }

  .row2 {
    display: flex;
    justify-content: end;

    .cancelsavebtn {
      display: flex;
      gap: 14px;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 8px 18px;
        border: none;
        color: #fff;
        border-radius: 4px;
      }
      .cancel {
        background-color: #777777;
      }

      .save {
        background-color: #595f72;
      }
    }
  }
}
