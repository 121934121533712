@import "../../../App.scss";
.listingMain {
  background: $div-bg-color;
  height: calc(100% - 180px);
  overflow: scroll;
  padding: 30px;
  @include laptopscreen {
    padding: 20px;
  }
  .pinkText {
    color: $pink-color !important;
  }
  p {
    font-weight: bold;
    font-size: 16px;
    color: $userListing-color;
  }
  .SearchBar {
    display: flex;
    img {
      margin-right: 5px;
    }
    input {
      outline: none;
      border: none;
      overflow: hidden;
      size: 12px;
    }
    input::placeholder {
      font-size: 10px;
    }
    input:focus {
      outline: none;
    }
  }
  .TableListngLayOut {
    background: $White-color;
    min-height: 300px;
    max-height: 300px;
    flex-wrap: wrap;
    overflow-y: scroll;
    padding: 10px 25px 20px 25px;
    border-radius: 5px;
    table {
      width: 100%;
    }
    .ListingIcons {
      display: flex;
      justify-content: space-around;
      align-items: center;
      svg {
        cursor: pointer;
        font-size: 18px;
      }
      img {
        cursor: pointer;
        width: 18px;
        height: 18px;
      }
    }
    tr {
      th {
        padding: 20px 0px 0px 0px;
        color: $userListing-color;
        font-size: 14px;
        font-weight: bold;
        border-bottom: 2px solid #e6eaf0;
      }
      td {
        padding: 5px 0px 7px 0px;
        color: $userListing-color;
        font-size: 14px;
        font-weight: 400;
        color: $userListing-color;
        border-bottom: 2px solid #e6eaf0;
      }
      .ListingIcons {
        display: flex;
        justify-content: space-around;
        align-items: center;
        svg {
          color: $userListing-color;
          cursor: pointer;
          font-size: 18px;
        }
        img {
          cursor: pointer;
          width: 18px;
          height: 18px;
        }
      }
      .actionContainer {
        button:nth-child(odd) {
          margin-right: 10px;
        }
      }
    }
  }
  .PaginationWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
    p {
      margin: 0;
      cursor: pointer;
      margin-right: 10px;
      font-size: 14px;
      font-weight: 500;
      color: $pink-color;
    }
  }
  .AddUseAsSection {
    display: flex;
    justify-content: end;
    padding: 10px;
    padding-right: 0 !important;
  }
  .NoData {
    pointer-events: none;
    height: 200px;
    .LoaderWrap {
      text-align: center;
      border: unset;
      vertical-align: middle;
    }
  }
}
