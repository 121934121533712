@import "../../App.scss";
.LanguageMain {
	position: relative;
	height: 100vh;
	width: 100%;
	.LanguageListingSection {
		width: 100%;
		height: calc(100% - 233px);
		padding: 0px 20px;
		overflow-x: auto;
		table {
			width: 100%;
			@media screen and (max-width: 1024px) {
				// width: 950px;
			}
		}
		.NoDataText {
			text-align: center;
			border: none;
			font-size: 32px;
			font-weight: 600;
		}
		tr {
			.LoaderWrap {
				text-align: center;
				border: unset;
				height: 50vh;
			}
			th {
				padding: 20px 0px;
				color: $userListing-color;
				font-size: 14px;
				font-family: $Poppins-SemiBold !important;
				border-bottom: 2px solid #e6eaf0;
			}
			td {
				padding: 20px 0;
				color: $userListing-color;
				font-size: 14px;
				font-weight: 400;
				border-bottom: 2px solid #e6eaf0;
				textarea {
					border-radius: 5px;
					border: 2px solid $fild-border-color;
					background-color: $White-color;
					padding: 5px;
					font-size: 14px;
					width: 95%;
				}
				textarea:focus {
					outline: none;
				}
				input {
					border-radius: 5px;
					border: 2px solid $fild-border-color;
					background-color: $White-color;
					padding: 5px;
					font-size: 14px;
					width: 80%;
				}
				input:focus {
					outline: none;
				}
				button {
					background-color: $footer-color;
					border: 0;
					color: $White-color;
					border-radius: 5px;
					padding: 5px 15px;
				}
			}
		}
	}
}
