@import "../../../../App.scss";
.CreateMenuMain {
  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: 400px;
    margin: 0;
    margin-bottom: 15px;
  }
  .CreateMenuWrap {
    text-align: left;
    h6 {
      font-size: 14px;
      font-weight: 500;
      margin: 0;
      margin-bottom: 15px;
    }
    .uploadMain {
      label {
        width: 100%;
        .PhotoUpload {
          width: 100%;
          height: 170px;
          border: 1px solid $gray-color;
          border-radius: 5px;
          @include flex;
          .img1 {
            width: 100%;
            height: 100%;
            object-fit: contain;
            padding: 10px 0px;
          }
        }
      }
    }
    .InputWrap {
      margin-top: 15px;
      p {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        margin-bottom: 10px;
      }

      input {
        border: none;
        width: 100%;
        border-bottom: 1px solid $input-border-color;
      }
      input:focus {
        outline: none;
      }
      button {
        background-color: $footer-color;
        border: none;
        border-radius: 5px;
        color: $White-color;
        padding: 10px 20px;
      }
    }
    .FlexBox {
      display: flex;
      .InputWrap {
        margin-top: 15px;
        width: 100%;
        p {
          font-size: 14px;
          font-weight: 500;
          margin: 0;
          margin-bottom: 10px;
        }
        select {
          border: none;
          width: 100%;
          border-bottom: 1px solid $input-border-color;
        }
        select:focus {
          outline: none;
        }
      }
      .InputWrap:last-child {
        margin-left: 20px;
      }
    }
    .IsDefalutWrap {
      margin-top: 15px;
      display: flex;
      p {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        margin-bottom: 10px;
        margin-left: 15px;
      }
      .radioCustom {
        opacity: 0;
        position: absolute;
      }
      .radioCustom,
      .radioCustomLabel {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
      }
      .radioCustomLabel {
        position: relative;
      }
      .radioCustom + .radioCustomLabel:before {
        content: "";
        background: #fff;
        border: 1px solid $gray-color;
        display: inline-block;
        width: 16px;
        height: 16px;
        padding: 5px;
        border-radius: 2px;
        text-align: center;
      }

      .radioCustom:checked + .radioCustomLabel:before {
        background: url(../../../../assets//icons/IsDefalutCheckIcon.svg);
        background-size: 15px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .btnWrap {
      margin-top: 15px;
      display: flex;
      justify-content: flex-end;
      button {
        background-color: $pink-color;
        border: none;
        border-radius: 5px;
        color: $White-color;
        padding: 10px;
      }
    }
  }
}
