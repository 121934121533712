@import "../../../App.scss";

.containerHeader {
  background-color: $header-color;
  box-sizing: border-box;
  padding: 1.8% 2.4%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #d2d2d2;
  @include tabletNew {
    padding: 1% 2%;
  }
  .CanvasWarp {
    display: none;
    @include tablet {
      display: block;
      button {
        border: none;
        border-radius: 5px;
        background-color: $footer-color;
        @include flex;
        padding: 10px;
        svg {
          color: $White-color;
        }
      }
    }
  }
  .BreadCrumbLayout {
    display: flex;
    position: relative;
    top: 25px;

    .semiBoldLightText {
      font-family: $Poppins-SemiBold;
      font-size: 14px;
      line-height: 25px;
      color: $gray-color-font;
      opacity: 0.5;
      cursor: pointer;
      @include tabletNew {
        font-size: 11px;
      }
    }
    .hyphen {
      padding: 0 20px;
      @include tabletNew {
        padding: 0 15px;
      }
    }
    .semiBoldMedText {
      font-family: $Poppins-SemiBold;
      font-size: 14px;
      line-height: 25px;
      color: $gray-color-font;
      @include tabletNew {
        font-size: 11px;
      }
    }
  }
  .ActionOnUser {
    display: flex;
    .buttonLayout {
      display: flex;
      margin: 0 10px;

      button {
        background-color: $userListing-color;
        color: $White-color;
        border-radius: 5px;
        border: none;
        outline: none;
        padding: 6px 12px;
        font-family: $Poppins-Regular;
        font-size: 14px;
        @include tabletNew {
          font-size: 11px;
          padding: 4px 8px !important;
        }
        img {
          margin-left: 10px;
        }
      }
      .addUserbtn {
        margin-right: 20px;
      }
    }
  }
}
