@import "../../../../App.scss";
.LoginHistoryMain {
	h3 {
		text-align: center;
		font-size: 18px;
		font-family: $Poppins-SemiBold !important;
		margin: 0;
		margin-bottom: 15px;
	}
	.LoginHistoryWrap {
		width: 100%;
		padding: 0px 20px;
		height: 200px;
		overflow-y: auto;
		overflow-x: auto;
		table {
			width: 100%;
		}
		tr {
			.LoaderWrap {
				text-align: center;
				border: unset;
				height: 40vh;
			}
			th {
				font-size: 18px;
				font-weight: 500;
				color: $userListing-color;
			}
			td {
				font-size: 14px;
				font-weight: 500;
				color: $userListing-color;
			}
			.time {
				text-align: right;
			}
		}
	}
}
