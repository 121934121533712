@import "../../../App.scss";
.branchesFormSection {
  padding: 10px 20px;

  .error {
    color: red;
  }
  .inputsWrapper {
    display: flex;
    flex-direction: column;

    .customDropDown {
      max-height: 300px;
      overflow: auto;
      background-color: #fff;
      border-radius: 12px;

      .selectedBorder {
        background-color: $pink-color;
        width: 100%;
        border-radius: 5px;
        color: $White-color;
        padding: 5px;
      }
      .disabledBorder {
        background-color: $line-color;
        width: 100%;
        border-radius: 5px;
        padding: 5px;
      }

      .dropdownBar {
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
      }

      ul {
        color: #595f72;
        padding: 0 15px;
        li {
          list-style: none;
          img {
            margin-right: 12px;
          }
        }
      }
    }
    .Text {
      display: flex;
      flex-direction: column;
    }
    .SelectText {
      input {
        padding: 3px 10px !important;
      }
    }
    input,
    select,
    textarea {
      border: $input-border-color;
      outline: none;
      padding: 10px 10px;

      border-radius: 5px;
    }
    textarea {
      resize: none;
      height: 150px;
    }
    // textarea {
    //   border: $input-border-color;
    //   outline: none;
    //   padding: 10px 10px;
    //   border-radius: 5px;
    // }
    label {
      margin: 15px 0;
      font-weight: 700;
    }
    .uploadPicWrapper {
      display: flex;
      gap: 12px;

      .imageprview {
        .preview {
          position: relative;
          @include flex;
          width: 200px;
          height: 200px;
          background-color: #fff;
          // border: 8px solid #fff;
          border-radius: 5px;
          .innerpreview {
            p {
              font-weight: 700;
            }
            img {
              object-fit: "fill";
              width: 200px;
              height: 200px;
            }
            border-radius: 5px;
            @include flex;
            width: 190px;
            height: 190px;
            background-color: $div-bg-color;
          }
        }
        .cross {
          position: absolute;
          top: 12px;
          right: 12px;

          img {
            width: 23px;
            color: #fff;
          }
        }
      }

      .upload {
        display: flex;
        gap: 12px;
        align-items: end;
        .uploadImg {
          @include flex;
          width: 50px;
          height: 50px;
          background: #fff;
          border-radius: 5px;
          padding: 12px;
          cursor: pointer;
          img {
            width: 24px;
            cursor: pointer;
          }
        }
        .labelupload {
          label {
            margin: auto 0 !important;
            cursor: pointer;
          }
        }
      }
    }
  }
  .btnwrapper {
    margin-top: 30px;
    display: flex;
    justify-content: end;
    margin-left: 20px;
    .btnWrapperShift {
      display: flex;
      gap: 12px;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 8px 18px;
        border: none;
        color: #fff;
        border-radius: 4px;
      }
    }

    .btncancel {
      background-color: #777777;
    }
    .btnadd {
      width: 100%;
      background-color: #595f72;
    }
  }
}
