@import "../../../App.scss";
.HeaderWrap {
	.ManagmentSection {
		background-color: $header-color;
		padding: 20px 30px;
		border: 2px solid #d2d2d2;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include tabletNew {
			flex-wrap: wrap;
			justify-content: unset;
		}
		.CanvasWarp {
			display: none;
			@include tabletNew {
				margin-right: 20px;
			}
			@include tablet {
				display: block;
				margin-bottom: 10px;
				button {
					border: none;
					border-radius: 5px;
					background-color: $footer-color;
					@include flex;
					padding: 10px;
					svg {
						color: $White-color;
					}
				}
			}
		}
		.FilterSection {
			h5 {
				font-size: 16px;
				font-weight: 400;
				margin: 0;
				margin-bottom: 10px;
				color: $gray-color-font;
			}
			.managementFild {
				display: flex;
				.fildBox {
					margin-right: 10px;
					select {
						border-radius: 5px;
						border: 2px solid $fild-border-color;
						background-color: $White-color;
						padding: 5px;
						font-size: 14px;
						width: 90px;
						-webkit-appearance: none;
						background-image: url(../../../assets/icons/DropdownIcon.svg);
						background-position: right 5px center;
						background-repeat: no-repeat;
					}
					select:focus {
						outline: none;
					}
					button {
						background-color: $footer-color;
						border: 0;
						color: $White-color;
						border-radius: 5px;
						padding: 5px;
					}
				}
			}
		}
		.ManagbBnnerSection {
			@include tabletNew {
				margin-top: 10px;
			}
			button {
				background-color: $footer-color;
				border: 0;
				color: $White-color;
				border-radius: 5px;
				padding: 5px;
				img {
					margin-left: 10px;
				}
			}
		}
	}
	.MenuPerPageSection {
		background-color: $header-color;
		padding: 20px 30px;
		border: 2px solid #d2d2d2;
		display: flex;
		align-items: center;
		p {
			font-size: 14px;
			font-weight: 400;
			margin: 0;
			color: $gray-color-font;
		}
		.numberOfUser {
			select {
				border: none;
				background-color: $transparent-color;
				font-size: 14px;
				margin-left: 10px;
				width: 40px;
				-webkit-appearance: none;
				background-image: url(../../../assets/icons/DropdownIcon.svg);
				background-position: right 5px center;
				background-repeat: no-repeat;
			}
			select:focus {
				outline: none;
			}
		}
	}
}