@font-face {
  font-family: "Poppins-BoldItalic";
  src: url("../fonts/font/Poppins-BoldItalic.eot");
  src: url("../fonts/font/Poppins-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/font/Poppins-BoldItalic.woff2") format("woff2"),
    url("../fonts/font/Poppins-BoldItalic.woff") format("woff"),
    url("../fonts/font/Poppins-BoldItalic.ttf") format("truetype"),
    url("../fonts/font/Poppins-BoldItalic.svg#Poppins-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-BlackItalic";
  src: url("../fonts/font/Poppins-BlackItalic.eot");
  src: url("../fonts/font/Poppins-BlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/font/Poppins-BlackItalic.woff2") format("woff2"),
    url("../fonts/font/Poppins-BlackItalic.woff") format("woff"),
    url("../fonts/font/Poppins-BlackItalic.ttf") format("truetype"),
    url("../fonts/font/Poppins-BlackItalic.svg#Poppins-BlackItalic")
      format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-ExtraLight";
  src: url("../fonts/font/Poppins-ExtraLight.eot");
  src: url("../fonts/font/Poppins-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/font/Poppins-ExtraLight.woff2") format("woff2"),
    url("../fonts/font/Poppins-ExtraLight.woff") format("woff"),
    url("../fonts/font/Poppins-ExtraLight.ttf") format("truetype"),
    url("../fonts/font/Poppins-ExtraLight.svg#Poppins-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Black";
  src: url("../fonts/font/Poppins-Black.eot");
  src: url("../fonts/font/Poppins-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/font/Poppins-Black.woff2") format("woff2"),
    url("../fonts/font/Poppins-Black.woff") format("woff"),
    url("../fonts/font/Poppins-Black.ttf") format("truetype"),
    url("../fonts/font/Poppins-Black.svg#Poppins-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/font/Poppins-Bold.eot");
  src: url("../fonts/font/Poppins-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/font/Poppins-Bold.woff2") format("woff2"),
    url("../fonts/font/Poppins-Bold.woff") format("woff"),
    url("../fonts/font/Poppins-Bold.ttf") format("truetype"),
    url("../fonts/font/Poppins-Bold.svg#Poppins-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-ExtraBoldItalic";
  src: url("../fonts/font/Poppins-ExtraBoldItalic.eot");
  src: url("../fonts/font/Poppins-ExtraBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/font/Poppins-ExtraBoldItalic.woff2") format("woff2"),
    url("../fonts/font/Poppins-ExtraBoldItalic.woff") format("woff"),
    url("../fonts/font/Poppins-ExtraBoldItalic.ttf") format("truetype"),
    url("../fonts/font/Poppins-ExtraBoldItalic.svg#Poppins-ExtraBoldItalic")
      format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("../fonts/font/Poppins-ExtraBold.eot");
  src: url("../fonts/font/Poppins-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/font/Poppins-ExtraBold.woff2") format("woff2"),
    url("../fonts/font/Poppins-ExtraBold.woff") format("woff"),
    url("../fonts/font/Poppins-ExtraBold.ttf") format("truetype"),
    url("../fonts/font/Poppins-ExtraBold.svg#Poppins-ExtraBold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Thin";
  src: url("../fonts/font/Poppins-Thin.eot");
  src: url("../fonts/font/Poppins-Thin.eot?#iefix") format("embedded-opentype"),
    url("../fonts/font/Poppins-Thin.woff2") format("woff2"),
    url("../fonts/font/Poppins-Thin.woff") format("woff"),
    url("../fonts/font/Poppins-Thin.ttf") format("truetype"),
    url("../fonts/font/Poppins-Thin.svg#Poppins-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-ExtraLightItalic";
  src: url("../fonts/font/Poppins-ExtraLightItalic.eot");
  src: url("../fonts/font/Poppins-ExtraLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/font/Poppins-ExtraLightItalic.woff2") format("woff2"),
    url("../fonts/font/Poppins-ExtraLightItalic.woff") format("woff"),
    url("../fonts/font/Poppins-ExtraLightItalic.ttf") format("truetype"),
    url("../fonts/font/Poppins-ExtraLightItalic.svg#Poppins-ExtraLightItalic")
      format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/font/Poppins-Medium.eot");
  src: url("../fonts/font/Poppins-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/font/Poppins-Medium.woff2") format("woff2"),
    url("../fonts/font/Poppins-Medium.woff") format("woff"),
    url("../fonts/font/Poppins-Medium.ttf") format("truetype"),
    url("../fonts/font/Poppins-Medium.svg#Poppins-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Italic";
  src: url("../fonts/font/Poppins-Italic.eot");
  src: url("../fonts/font/Poppins-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/font/Poppins-Italic.woff2") format("woff2"),
    url("../fonts/font/Poppins-Italic.woff") format("woff"),
    url("../fonts/font/Poppins-Italic.ttf") format("truetype"),
    url("../fonts/font/Poppins-Italic.svg#Poppins-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/font/Poppins-Regular.eot");
  src: url("../fonts/font/Poppins-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/font/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/font/Poppins-Regular.woff") format("woff"),
    url("../fonts/font/Poppins-Regular.ttf") format("truetype"),
    url("../fonts/font/Poppins-Regular.svg#Poppins-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-SemiBoldItalic";
  src: url("../fonts/font/Poppins-SemiBoldItalic.eot");
  src: url("../fonts/font/Poppins-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/font/Poppins-SemiBoldItalic.woff2") format("woff2"),
    url("../fonts/font/Poppins-SemiBoldItalic.woff") format("woff"),
    url("../fonts/font/Poppins-SemiBoldItalic.ttf") format("truetype"),
    url("../fonts/font/Poppins-SemiBoldItalic.svg#Poppins-SemiBoldItalic")
      format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-LightItalic";
  src: url("../fonts/font/Poppins-LightItalic.eot");
  src: url("../fonts/font/Poppins-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/font/Poppins-LightItalic.woff2") format("woff2"),
    url("../fonts/font/Poppins-LightItalic.woff") format("woff"),
    url("../fonts/font/Poppins-LightItalic.ttf") format("truetype"),
    url("../fonts/font/Poppins-LightItalic.svg#Poppins-LightItalic")
      format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Light";
  src: url("../fonts/font/Poppins-Light.eot");
  src: url("../fonts/font/Poppins-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/font/Poppins-Light.woff2") format("woff2"),
    url("../fonts/font/Poppins-Light.woff") format("woff"),
    url("../fonts/font/Poppins-Light.ttf") format("truetype"),
    url("../fonts/font/Poppins-Light.svg#Poppins-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-MediumItalic";
  src: url("../fonts/font/Poppins-MediumItalic.eot");
  src: url("../fonts/font/Poppins-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/font/Poppins-MediumItalic.woff2") format("woff2"),
    url("../fonts/font/Poppins-MediumItalic.woff") format("woff"),
    url("../fonts/font/Poppins-MediumItalic.ttf") format("truetype"),
    url("../fonts/font/Poppins-MediumItalic.svg#Poppins-MediumItalic")
      format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../fonts/font/Poppins-SemiBold.eot");
  src: url("../fonts/font/Poppins-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/font/Poppins-SemiBold.woff2") format("woff2"),
    url("../fonts/font/Poppins-SemiBold.woff") format("woff"),
    url("../fonts/font/Poppins-SemiBold.ttf") format("truetype"),
    url("../fonts/font/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-ThinItalic";
  src: url("../fonts/font/Poppins-ThinItalic.eot");
  src: url("../fonts/font/Poppins-ThinItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/font/Poppins-ThinItalic.woff2") format("woff2"),
    url("../fonts/font/Poppins-ThinItalic.woff") format("woff"),
    url("../fonts/font/Poppins-ThinItalic.ttf") format("truetype"),
    url("../fonts/font/Poppins-ThinItalic.svg#Poppins-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
