@import "../../../App.scss";
.classificationFormSection {
  padding: 10px 20px;
  .error {
    color: red;
  }
  .Text {
    display: flex;
    flex-direction: column;
  }
  .inputsWrapper {
    display: flex;
    flex-direction: column;

    .customDropDown {
      max-height: 300px;
      overflow: auto;
      background-color: #fff;
      // padding: 0 12px;
      border-radius: 12px;

      .selectedBorder {
        cursor: pointer;
        background-color: $pink-color;
        width: 100%;
        border-radius: 5px;
        color: $White-color;
        padding: 5px;
      }
      .disabledBorder {
        background-color: $line-color;
        width: 100%;
        border-radius: 5px;
        padding: 5px;
      }

      .dropdownBar {
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
      }

      ul {
        color: #595f72;
        padding: 0 15px;
        li {
          list-style: none;
          img {
            margin-right: 12px;
          }
        }
      }
    }

    input,
    select {
      border: $input-border-color;
      outline: none;
      padding: 10px 10px;

      border-radius: 5px;
    }
    label {
      margin: 15px 0;
      font-weight: 700;
    }
  }
  .btnwrapper {
    width: 100%;
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    margin-top: 30px;
    margin-right: 30px;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 8px 18px;
      border: none;
      color: #fff;
      border-radius: 4px;
    }

    .btncancel {
      background-color: #777777;
    }

    .btnadd {
      //   width: 100%;
      background-color: #595f72;
    }
  }
}
