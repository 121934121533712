@import "../../../App.scss";
.AddInternalBannerContainer {
  display: flex;
  flex-direction: column;
  span {
    color: $pink-color;
  }
  .LoaderWrap {
    text-align: center;
    border: unset;
    height: 50vh;
  }
  .simpleText {
    color: $pink-color;
    font-weight: 500 !important;
    font-size: 13px !important;
  }
  .errorText {
    font-weight: 500 !important;
    color: red !important;
    font-size: 12px !important;
  }
  h3 {
    font-weight: bold;
  }
  .AddInternalBanner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;

    .SearchBar {
      display: flex;
      align-items: center;
      width: 100%;
      border-radius: 5px;
      border: 2px solid $input-border-color;
      padding: 7px;
      background-color: $White-color;
      svg {
        margin-right: 5px;
      }
      input {
        all: unset;
      }
    }
    label {
      font-weight: bold;
      margin-top: 7px;
      margin-bottom: 7px;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      width: 30px;
      height: 30px;
      content: url("../../../assets/icons/calenderIcon.png");
    }
    input[type="date"] {
      padding: 0;
      padding-left: 5px;
      margin: 0;
    }
    input,
    select {
      margin-bottom: 7px;
      border-radius: 5px;
      border: 2px solid $input-border-color;
      background-color: $White-color;
      padding: 7px;
      font-size: 14px;
      width: 100%;
    }
    input:focus,
    select:focus {
      outline: none;
    }
    .DataContainer {
      padding: 20px;
    }
    .inputContainer {
      display: flex;
      justify-content: space-between;
      gap: 15px;
      .inputbox {
        display: flex;
      }
      .iconContainer {
        background-color: $input-border-color;
        height: max-content;
        padding: 7px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        svg {
          color: $userListing-color;
          font-size: 20px;
        }
      }
    }
    .inputWrapper {
      display: flex;
      gap: 15px;
      .regionContainer {
        width: 70%;
      }
    }
    .ToggleWrap {
      width: 100%;
      p {
        font-weight: bold;
        margin-top: 7px;
        margin-bottom: 7px;
      }
      margin-top: 15px;
      .Switch {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 20px;
      }
      .Switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      .Slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #d1d1d1;
        transition: 0.4s;
        border-radius: 34px;

        &::before {
          position: absolute;
          content: "";
          height: 16px;
          width: 16px;
          top: 2px;
          left: 3px;
          bottom: 2.3px;
          background-color: white;
          transition: 0.4s;
          border-radius: 50%;
        }
      }
      input:checked + .Slider {
        background-color: blue;
        background-color: $pink-color;
      }
      input:checked + .Slider:before {
        transform: translateX(26px);
        background-color: white;
        left: -4px;
      }
    }
    .bannerContainer {
      display: flex;
      align-items: center;
      color: $userListing-color;
      gap: 10px;
      margin: 20px 0px;
      position: relative;
      p {
        margin: 0;
      }
      .icon {
        cursor: pointer;
        background: $White-color;
        padding: 5px 10px;
        border-radius: 5px;
        border: none;
      }
      .searchContainer {
        position: relative;
        .preview {
          z-index: 999;
          position: absolute;
          @include flex;
          width: 200px;
          height: 200px;
          background-color: $White-color;
          border-radius: 5px;
          top: 70%;
          right: 50%;
          .innerpreview {
            img {
              //   object-fit: fit;
              width: 100%;
              height: 100%;
            }
            p {
              font-weight: 700;
            }
            border-radius: 5px;
            @include flex;
            width: 190px;
            height: 190px;
            background-color: $div-bg-color;
          }
          .cross {
            position: absolute;
            top: 12px;
            right: 12px;
            svg {
              color: $userListing-color;
            }
          }
        }
      }
    }
    .TotalContainer {
      margin: 10px 0px;
      display: flex;
      flex-direction: column;
      border-bottom: 2px solid $line-color;
      color: $userListing-color;
      font-weight: 600;
      .inputbox {
        display: flex;
        justify-content: space-between;
      }
    }
    .InclContainer {
      color: $black-color;
      border-bottom: none;
    }
  }
  .BtnContainer {
    align-self: flex-end;
    display: flex;
    gap: 14px;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 8px 18px;
      border: none;
      color: #fff;
      border-radius: 4px;
    }
    .cancel {
      background-color: #777777;
    }
    .save {
      background-color: #595f72;
    }
  }
  .Modal {
    width: 100%;
  }
  .loaderContainer {
    margin-left: 5px;
  }
  .ImageLabel {
    display: flex;
  }
}
