@import "../../../App.scss";
.detailPage {
  overflow-y: auto;
  height: calc(100% - 200px);
  border: 2px solid $line-color;
  .errorText {
    font-weight: 500 !important;
    color: red !important;
    font-size: 12px !important;
  }
  .NoData {
    pointer-events: none;
    height: 200px;
    td {
      border: none !important;
    }
    .LoaderWrap {
      text-align: center;
      border: unset;
    }
  }
  .starIcon {
    margin-right: 10px;
    width: 15px;
    height: 15px;
  }
  button{
    background-color: $userListing-color;
    color: $White-color;
    border-radius: 10px;
    border: none;
    outline: none;
    padding: 6px 12px;
    font-family: "Poppins-Regular";
    font-size: 14px;
  }
  .sent {
    background-color: #79c279ab !important;
    color: black !important;
    border-radius: 10px !important;
    border: none !important;
    outline: none !important;
    padding: 6px 12px !important;
    font-family: "Poppins-Regular" !important;
    font-size: 14px !important;
  }
  .draft {
    background-color: #97b8bdb5 !important;
    color: black !important;
    border-radius: 10px !important;
    border: none !important;
    outline: none !important;
    padding: 6px 12px !important;
    font-family: "Poppins-Regular" !important;
    font-size: 14px !important;
  }
  .createdAt {
    background: $div-bg-color !important;
    border: 1px solid $userListing-color !important;
  }
  .gridWrapper {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid $line-color;
    padding: 25px 35px 20px 25px;
    background: $div-bg-color;
    button {
      margin-top: 20px;
      align-self: flex-end;
      justify-self: flex-end;
    }
  }
  .girdContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    background: $div-bg-color;
    .inputContainer {
      display: flex;
      align-items: center;
      .iconContainer {
        cursor: pointer;
        background-color: $input-border-color;
        padding: 5px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        svg {
          color: $userListing-color;
          font-size: 20px;
        }
      }
      select {
        border-radius: 5px;
        border: 2px solid $fild-border-color;
        background-color: $White-color;
        padding: 7px;
        font-size: 14px;
        width: 90px;
        -webkit-appearance: none;
        background-image: url(../../../assets/icons/DropdownIcon.svg);
        background-position: right 5px center;
        background-repeat: no-repeat;
      }
      select:focus {
        outline: none;
      }
    }
    .labelContainer {
      margin-top: 10px !important;
    }
    select {
      border-radius: 5px;
      border: 2px solid $fild-border-color;
      background-color: $White-color;
      padding: 7px;
      font-size: 14px;
      width: 100%;
      -webkit-appearance: none;
      background-image: url(../../../assets/icons/DropdownIcon.svg);
      background-position: right 5px center;
      background-repeat: no-repeat;
    }
    select:focus {
      outline: none;
    }
    label {
      font-weight: bold;
      font-size: 12px;
      color: $userListing-color;
    }
    input {
      border-radius: 5px;
      border: 2px solid $input-border-color;
      background-color: $White-color;
      padding: 5px;
      font-size: 14px;
      width: 100%;
    }
    input:focus,
    select:focus {
      outline: none;
    }
  }
  .girdContainer > div:nth-last-child(-n + 2):nth-child(odd) {
    grid-column: 1;
  }

  .girdContainer > div:nth-last-child(-n + 2):nth-child(even) {
    grid-column: 4;
  }

  .address {
    background: $div-bg-color;
    padding: 25px 35px 25px 25px;
    height: 100%;
    .headerwrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1rem;
    }
    .contact {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      text-align: center;
    }
    p {
      color: $userListing-color;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .iconsList {
      align-self: flex-end;
      display: flex;
      gap: 10px;
      font-size: 10px;
      color: $userListing-color;
      margin-bottom: 3px;
    }
    .addressContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      // min-width: 800px;
      @include tabletNew {
        min-width: 0px !important;
        max-width: 800px;
      }
      button {
        // margin-top: 20px;
        align-self: flex-end;
        width: 80px;
      }
    }
    .tableContainer {
      background: $White-color;
      width: 100%;
      padding: 15px;
      .SearchBar {
        img {
          margin-right: 5px;
        }
        input {
          outline: none;
          border: none;
          size: 14px;
        }
        input::placeholder {
          font-size: 14px;
        }
        input:focus,
        textarea:focus {
          outline: none;
        }
      }
      .TableListngLayOut {
        width: 100%;
        padding: 1% 0;
        min-height: 200px;
        flex-wrap: wrap;
        overflow-y: scroll;
        .title {
          color: #df1681;
        }
        .select {
          border-radius: 5px;
          border: 2px solid #e9e9e9;
          background-color: #ffffff;
          padding: 5px;
          font-size: 14px;
          width: 60%;
        }
        table {
          width: 100%;
        }
        .tdStyle {
          padding-left: 15px;
          padding-right: 15px;
        }
        .iconStyle {
          padding-right: 15px;
        }
        .setContent {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
        }
        tr {
          th {
            padding: 20px 0px 0px 0px;
            color: $userListing-color;
            font-size: 14px;
            font-weight: bold;
            border-bottom: 2px solid #e6eaf0;
          }
          th:first-child {
            border: none !important;
          }
          td {
            padding: 5px 0px 7px 0px;
            color: $userListing-color;
            font-size: 14px;
            font-weight: 400;
            color: $userListing-color;
            border-bottom: 2px solid #e6eaf0;
          }
          td:first-child {
            border: none !important;
          }
          .ListingIcons {
            display: flex;
            justify-content: space-around;
            align-items: center;
            svg {
              color: $userListing-color;
              cursor: pointer;
              font-size: 18px;
            }
            img {
              cursor: pointer;
              width: 18px;
              height: 18px;
            }
          }
          .tdIcon {
            cursor: pointer;
            svg {
              color: $userListing-color;
              font-size: 18px;
            }
          }
          .notUser {
            svg {
              color: $userListing-color;
              font-size: 18px;
            }
          }
        }
        .searchContainer {
          position: relative;

          .preview {
            position: absolute;
            padding: 5px;
            max-width: 400px;
            background: $White-color;
            border-radius: 5px;
            top: 30%;
            left: 7%;
            z-index: 1;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);

            &::after {
              content: "";
              position: absolute;
              top: 20%;
              right: 100%;
              margin-top: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: transparent $White-color transparent transparent;
            }
          }

          .preview:hover {
            // opacity: 1;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
          }
        }
      }
      .contactTable {
        min-height: 250px;
        max-height: 250px;
      }
    }
  }
  .container {
    border-bottom: 2px solid $line-color;
  }
  .otherOrgDetailsWrapper {
    display: flex;
    flex: column;
    background: $div-bg-color;
    border-bottom: 2px solid $line-color;
    padding-bottom: 20px;
    .otherOrgDetails {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;

      padding: 25px 35px 25px 25px;
      .inputContainer {
        display: flex;
        flex-direction: column;
        div {
          margin-bottom: 15px;
        }
        button {
          align-self: flex-end;
        }
      }
      label {
        font-weight: bold;
        font-size: 14px;
        color: $userListing-color;
        margin-bottom: 10px;
      }
      input,
      select {
        border-radius: 5px;
        border: 2px solid $input-border-color;
        background-color: $White-color;
        padding: 5px;
        font-size: 14px;
        width: 100%;
      }
      input,
      select:focus {
        outline: none;
      }
      .OrgStatusWrap {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        .changeproinputContainerider {
          svg {
            color: $White-color;
          }
          padding: 5px 15px;
          color: $White-color;
          border-radius: 20px;
          width: 100%;
          font-size: 12px;
          background: $provider-blue-color;
        }
        .StatusWrap {
          padding: 5px 0px;
          border-bottom: 1px solid #d2d2d2;
          display: flex;
          justify-content: space-between;
          .okText {
            font-size: 14px;
            color: $green-color;
          }
          p {
            color: #595f72;
            font-size: 14px;
            margin: 0;
          }
          &:last-child {
            border-bottom: unset;
          }
        }
      }
      button {
        margin-top: 20px;
        align-self: flex-end;
      }
    }
    button {
      align-self: flex-end;
    }
  }
  .branchAndClassContainer {
    display: flex;
    background: $div-bg-color;
    position: relative;
    .branchSection {
      flex: 1;
      padding: 20px;
      font-family: "Poppins-Regular";
      font-size: 14px;
      color: $userListing-color;
      font-weight: bold;

      p {
        font-size: 16px;
      }
      div {
        margin-top: 10px;
        width: 250px;
        // border-bottom: 2px solid $line-color;
      }
      .branchContainer {
        border-bottom: 2px solid $line-color;
      }
      div:last-child {
        border-bottom: none;
      }
      display: block;
      margin-bottom: 15px;
      input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
        border-bottom: 1px solid $line-color;
      }
      label {
        position: relative;
        cursor: pointer;
        &::before {
          content: "";
          zoom: 1;
          background-color: white;
          padding: 8px;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          cursor: pointer;
          margin-right: 7px;
          border-radius: 3px;
        }
      }
      input:checked + label:after {
        content: "";
        display: block;
        position: absolute;
        top: 4px;
        left: 5px;
        width: 6px;
        height: 10px;
        border: solid $userListing-color;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
    label {
      margin-left: 10px;
    }
    ul {
      list-style: none;
    }
  }
}
// .draft{
//   background: "#c6d4e5 !important"
// }
// .sent{
//   background: "green !important"
// }
