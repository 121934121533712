@import "../../../../App.scss";
.EditUsersListing {
  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 15px;
  }
  .errorText {
    color: red !important;
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .EditUsersListingWrap {
    display: flex;
    @include tablet {
      display: unset;
    }
    .EditUsersDetailsSection {
      width: 30%;
      border-right: 2px solid #d2d2d2;
      padding-right: 20px;
      padding-bottom: 20px;
      @include tablet {
        width: 100%;
        padding: unset;
        border: unset;
        padding-bottom: 20px;
      }
      .CarteOrgWarp {
        text-align: left;
        h6 {
          font-size: 14px;
          font-weight: 500;
          margin: 0;
          margin-bottom: 15px;
        }
        .uploadMain {
          label {
            width: 100%;
            .PhotoUpload {
              width: 100%;
              height: 170px;
              border: 1px solid $gray-color;
              border-radius: 5px;
              @include flex;
            }
          }
          .img1 {
            width: 100%;
            height: 100%;
            object-fit: contain;
            padding: 10px 0px;
          }
        }
        .InputWrap {
          display: flex;
          .FildBox {
            margin-top: 15px;
            width: 100%;
            p {
              font-size: 14px;
              font-weight: 500;
              margin: 0;
              margin-bottom: 10px;
            }
            input {
              border: none;
              width: 100%;
              border-bottom: 1px solid $input-border-color;
            }
            input:focus {
              outline: none;
            }
          }
          .FildBox:last-child {
            margin-left: 20px;
          }
        }
        .linkInputWrap {
          margin-top: 15px;
          width: 100%;
          p {
            font-size: 14px;
            font-weight: 500;
            margin: 0;
            margin-bottom: 10px;
          }
          input {
            border: none;
            width: 100%;
            border-bottom: 1px solid $input-border-color;
          }
          input:focus {
            outline: none;
          }
        }
      }
    }
    .EditUsersListingSection {
      width: 70%;
      padding-left: 20px;
      @include tablet {
        width: 100%;
        padding: unset;
      }
      .ListingSection {
        .ListingWrap {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .ListingBox {
            width: 80%;
            background-color: $ListingBox-color;
            border-radius: 5px;
            padding: 10px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 0px;
            @include desktopsmall {
              grid-template-columns: repeat(2, 1fr);
              grid-column-gap: 20px;
              grid-row-gap: 20px;
            }
            .LsitBox {
              border-bottom: 2px solid $input-border-color;
              width: 100%;
              p {
                font-size: 14px;
                font-weight: 500;
                margin: 0;
                margin-bottom: 5px;
              }
              select {
                background-color: $transparent-color;
                font-size: 14px;
                border: none;
                color: $th-color;
              }
              input {
                background-color: $transparent-color;
                border: none;
                width: 100%;
                color: $th-color;
              }
              input:focus {
                outline: none;
              }
            }
          }
          .AddOrRemoveListing {
            display: flex;
            width: 20%;
            .RemoveListing {
              width: 25px;
              height: 25px;
              border-radius: 50%;
              border: 2px solid $input-border-color;
              @include flex;
              font-size: 25px;
              cursor: pointer;
              margin-left: 20px;
            }
            .AddListing {
              width: 25px;
              height: 25px;
              border-radius: 50%;
              border: 2px solid $input-border-color;
              @include flex;
              font-size: 25px;
              cursor: pointer;
              margin-left: 20px;
            }
          }
        }
      }
      .ListingDetails {
        .ListngInput {
          margin-top: 15px;
          p {
            font-size: 14px;
            font-weight: 500;
            margin: 0;
            margin-bottom: 10px;
          }
          input {
            border: none;
            border-bottom: 1px solid $input-border-color;
          }
          input:focus {
            outline: none;
          }
        }
      }
      .EditbtnWrap {
        margin-top: 25px;
        display: flex;
        justify-content: flex-end;
        button {
          background-color: $pink-color;
          border: none;
          border-radius: 5px;
          color: $White-color;
          padding: 10px 50px;
        }
      }
    }
  }
}
