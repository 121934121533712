@import "../../../../App.scss";
.ListingViewsMain {
	h3 {
		text-align: center;
		font-size: 18px;
		font-weight: 400px;
		margin: 0;
		margin-bottom: 15px;
	}
	.ListingViewsWrap {
		width: 100%;
		padding: 0px 20px;
		height: 200px;      
		overflow-y: auto;
		overflow-x: auto;
		table {
			width: 100%;			
		}
		tr {
			th {
				font-size: 18px;
				font-weight: 500;
				color: $userListing-color;
			}
			td {
				font-size: 14px;
				font-weight: 500;
				color: $userListing-color;
			}
			.time {
				text-align: right;
			}
		}
	}
}
