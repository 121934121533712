@import "../../../App.scss";
.EditEmailMain {
	h3 {
		text-align: center;
		font-size: 18px;
		font-weight: 400px;
		margin: 0;
		margin-bottom: 15px;
	}
	.EditEmailWrap {
		.InputWrap {
			margin-top: 15px;
			.Recipientscount{
				display: flex;
				justify-content: flex-end;
				align-items: center;
				gap: 1rem;
			}
			.to{
				color: #df1681;
			}
			p {
				font-size: 14px;
				font-weight: 500;
				margin: 0;
			}
			input {
				width: 100%;
				border-bottom: 1px solid $input-border-color;
			}
			input:focus {
				outline: none;
			}
			select {
				margin-top: 10px;
				margin-bottom: 20px;
				border: none;
				width: 100%;
				border-bottom: 1px solid $input-border-color;
			}
			select:focus {
				outline: none;
			}
		}
		.InputWrap2{
			margin-top: 15px;
			width: 100%;
			display: flex;
			gap: 1rem;
			.selecttag{
				width: 100% !important;
			}

			.to{
				color: #df1681;
			}
			p {
				font-size: 14px;
				font-weight: 500;
				margin: 0;
			}
			input {
				width: 100%;
				border-bottom: 1px solid $input-border-color;
			}
			input:focus {
				outline: none;
			}
			select {
				margin-top: 10px;
				margin-bottom: 20px;
				border: none;
				width: 100%;
				border-bottom: 1px solid $input-border-color;
			}
			select:focus {
				outline: none;
			}
		}
        .btnWrap {
			margin-top: 15px;
			display: flex;
			gap: 1rem;
			.downloadBtn{
				background-color:#5e9f33;;
				border: none;
				border-radius: 5px;
				color: $White-color;
				padding: 10px;
			}
			button {
				background-color: $pink-color;
				border: none;
				border-radius: 5px;
				color: $White-color;
				padding: 10px;
			}
			.cancelBtn{
				background-color: $White-color;
				color : #df1681;
				border : 1px solid #df1681;
				padding: 10px;
				border-radius: 5px;
				
			}
		}
	}
}
