@import "../../App.scss";
.DiscountAndExtraMain {
  position: relative;
  height: 100vh;
  width: 100%;
  .SubscriptionSection {
    width: 100%;
    height: calc(100% - 187px);
    padding: 0px 20px;
    overflow-x: auto;
    table {
      width: 100%;
      @media screen and (max-width: 1024px) {
        width: 950px;
      }
    }
    tr {
      .dot {
        height: 7px;
        width: 7px;
        background-color: $userListing-color;
        border-radius: 50%;
        display: inline-block;
        margin-left: 5px;
      }
      th {
        padding: 20px 0px;
        color: $userListing-color;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 2px solid #e6eaf0;
      }
      th:last-child {
        border: none;
      }
      td {
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 20px 0;
        color: $userListing-color;
        font-size: 14px;
        font-weight: 400;
        border-bottom: 2px solid #e6eaf0;
      }
      td:last-child {
        overflow: unset;
      }
      .userEditor {
        border: none;
        cursor: pointer;
        position: relative;
        text-align: center;
        padding: 0px 5px;
        .EditorWrap {
          position: absolute;
          background-color: red;
          right: 0;
          text-align: left;
          margin-top: 10px;
          background-color: $White-color;
          width: 260px;
          filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.16));
          z-index: 1;
          display: block;
          padding: 20px;
          border-radius: 5px;
          ul {
            padding: 0;
            margin: 0;
            list-style: none;
            li {
              cursor: pointer;
              font-size: 14px;
              font-weight: 400;
              border-bottom: 1px solid $input-border-color;
              padding: 5px 0px;
              color: $userListing-color;
            }
            li:last-child {
              cursor: pointer;
              border: none;
            }
          }
        }
      }
    }
  }
}
