@import "../../../../App.scss";
.EditOrganisationMain {
  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 15px;
  }
  .errorText {
    color: red !important;
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .editOrgWrap {
    display: flex;
    @include tablet {
      display: unset;
    }
    .EditInfoSection {
      width: 30%;
      border-right: 2px solid #d2d2d2;
      padding-right: 20px;
      padding-bottom: 20px;
      @include tablet {
        width: 100%;
        padding: unset;
        border: unset;
        padding-bottom: 20px;
      }
      .CarteOrgWarp {
        text-align: left;

        h6 {
          font-size: 14px;
          font-weight: 600;
          margin: 0;
          margin-bottom: 15px;
        }
        .uploadMain {
          label {
            width: 100%;
            .PhotoUpload {
              width: 100%;
              height: 170px;
              border: 1px solid $gray-color;
              border-radius: 5px;
              @include flex;
              .img1 {
                width: 100%;
                height: 100%;
                object-fit: contain;
                padding: 10px 0px;
              }
            }
          }
        }
        .InputWrap {
          display: flex;
          .FildBox {
            margin-top: 15px;
            width: 100%;
            p {
              font-size: 14px;
              margin: 0;
              margin-bottom: 10px;
              font-weight: 600;
            }
            input {
              border: none;
              width: 100%;
              border-bottom: 1px solid $input-border-color;
            }
            input:focus {
              outline: none;
            }
            select {
              border: none;
              width: 100%;
              border-bottom: 1px solid $input-border-color;
            }
            select:focus {
              outline: none;
            }
          }
          .FildBox:last-child {
            margin-left: 20px;
          }
        }
        .linkInputWrap {
          margin-top: 15px;
          width: 100%;
          .subscriptionDetails {
            display: flex;
            p {
              margin: 0px;
            }
            span {
            }
            .activeText {
              margin-left: 10px;
              color: $green-color;
            }
            button {
              background-color: $footer-color;
              border: none;
              border-radius: 5px;
              color: $White-color;
              padding: 1px 17px;
              margin-left: 10px;
              margin-bottom: 10px;
            }
          }
          p {
            font-size: 14px;
            font-weight: 600;
            margin: 0;
            margin-bottom: 10px;
          }
          input {
            border: none;
            width: 100%;
            border-bottom: 1px solid $input-border-color;
          }
          input:focus {
            outline: none;
          }
        }
      }
    }
    .LeftSectionWrap {
      width: 40%;
      padding: 0px 20px;
      border-right: 2px solid #d2d2d2;
      @include tablet {
        width: 100%;
        padding: unset;
        border: unset;
        padding-bottom: 20px;
      }
      .UserOrgSection {
        h6 {
          font-size: 14px;
          font-weight: 600;
          margin: 0;
          margin-bottom: 15px;
          text-transform: capitalize;
        }
        .OrgContentWrap {
          border-radius: 5px;
          border: 1px solid #d2d2d2;
          height: 170px;
          overflow: auto;
          .NoData {
            pointer-events: none;
            .LoaderWrap {
              text-align: center;
              border: unset;
            }
          }
          table {
            width: 100%;
          }
          tr {
            th {
              font-size: 14px;
              font-weight: 500;
              color: $th-color;
              padding: 5px 20px;
              pointer-events: none;
            }
            .normaltd {
              font-size: 14px;
              font-weight: 500;
              color: $th-color;
              padding: 5px 20px;
              svg {
                color: $userListing-color;
              }
            }
            .selectedtd {
              background-color: $footer-color;
              font-size: 14px;
              font-weight: 500;
              color: $White-color;
              padding: 5px 20px;
              svg {
                color: $White-color;
              }
            }
            &:hover {
              background-color: $footer-color;
              td {
                color: $White-color;
                cursor: pointer;
                svg {
                  color: $White-color;
                }
              }
            }
          }
        }
        .EditOrgBtnWrap {
          display: flex;
          flex-wrap: wrap;
          margin-top: 15px;
          justify-content: flex-end;
          button {
            background-color: $footer-color;
            border: none;
            border-radius: 5px;
            color: $White-color;
            padding: 10px;
            margin-left: 10px;
            margin-bottom: 10px;
            &:first-child {
              margin-left: unset;
            }
            img {
              margin-left: 10px;
            }
          }
        }
        .linkInputWrap {
          margin-top: 15px;
          width: 100%;
          p {
            font-size: 14px;
            font-weight: 600;
            margin: 0;
            margin-bottom: 10px;
          }
          input {
            border: none;
            width: 60%;
            border-bottom: 1px solid $input-border-color;
          }
          input:focus {
            outline: none;
          }
          select {
            border: none;
            width: 60%;
            border-bottom: 1px solid $input-border-color;
          }
          select:focus {
            outline: none;
          }
        }
      }
      .ListingsOrgSection {
        margin-top: 20px;
        h6 {
          font-size: 14px;
          font-weight: 600;
          margin: 0;
          margin-bottom: 15px;
          text-transform: capitalize;
        }
        .OrgContentWrap {
          border-radius: 5px;
          border: 1px solid #d2d2d2;
          height: 170px;
          overflow: auto;
          .NoData {
            pointer-events: none;
            .LoaderWrap {
              text-align: center;
              border: unset;
            }
          }
          table {
            width: 100%;
          }
          tr {
            .LoaderWrap {
              text-align: center;
              border: unset;
              height: 20vh;
            }
            th {
              font-size: 14px;
              font-weight: 500;
              color: $th-color;
              padding: 5px 20px;
              pointer-events: none;
            }
            .normaltd {
              font-size: 14px;
              font-weight: 500;
              color: $th-color;
              padding: 5px 20px;
              svg {
                color: $userListing-color;
              }
            }
            .selectedtd {
              background-color: $footer-color;
              font-size: 14px;
              font-weight: 500;
              color: $White-color;
              padding: 5px 20px;
              svg {
                color: $White-color;
              }
            }
            &:hover {
              background-color: $footer-color;
              td {
                color: $White-color;
                cursor: pointer;
                svg {
                  color: $White-color;
                }
              }
            }
          }
        }
        .ChangeContactWrap {
          display: flex;
          justify-content: space-between;
          margin-top: 15px;
          .linkInputWrap {
            margin-top: 15px;
            p {
              font-size: 14px;
              font-weight: 600;
              margin: 0;
              margin-bottom: 10px;
            }
            input {
              border: none;
              width: 100%; // width: 60%;
              border-bottom: 1px solid $input-border-color;
            }
            input:focus {
              outline: none;
            }
            select {
              border: none;
              width: 100%; // width: 60%;
              border-bottom: 1px solid $input-border-color;
            }
            select:focus {
              outline: none;
            }
          }
          button {
            background-color: $footer-color;
            border: none;
            border-radius: 5px;
            color: $White-color;
            padding: 10px;
            margin-left: 10px;
            max-height: 50px;
            height: 100%;
            img {
              margin-left: 10px;
            }
          }
        }
      }
    }
    .EditBanner {
      width: 30%;
      padding-left: 20px;
      text-align: left;
      @include tablet {
        width: 100%;
        padding: unset;
      }
      h6 {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 15px;
      }
      .uploadMain {
        label {
          width: 100%;
          .PhotoUpload {
            width: 100%;
            height: 170px;
            border: 1px solid #d2d2d2;
            border-radius: 5px;
            @include flex;
            .img1 {
              width: 100%;
              height: 100%;
              object-fit: contain;
              padding: 10px 0px;
            }
          }
        }
      }
      .DescriptionWrap {
        margin-top: 15px;
        width: 100%;
        p {
          font-size: 14px;
          font-weight: 600;
          margin: 0;
          margin-bottom: 10px;
        }
        textarea {
          width: 100%;
          max-height: 130px;
          min-height: 130px;
          border: 1px solid #d2d2d2;
          border-radius: 5px;
          resize: none;
          padding: 15px;
          color: #777777;
          font-size: 14px;
        }
        textarea:focus {
          outline: none;
        }
        .errorText {
          color: red !important;
          font-size: 12px;
        }
      }
    }
  }
  .EditbtnWrap {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
    button {
      background-color: $pink-color;
      border: none;
      border-radius: 5px;
      color: $White-color;
      padding: 10px 50px;
    }
  }
}
