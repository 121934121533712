@import "../../../../App.scss";
.CreateOrgMain {
  h3 {
    text-align: center;
    font-size: 18px;
    font-family: $Poppins-SemiBold;
    margin: 0;
    margin-bottom: 15px;
  }
  .errorText {
    color: red !important;
    font-size: 12px !important;
    font-weight: unset !important;
  }
  .CarteOrgSection {
    display: flex;
    @include tablet {
      display: unset;
    }
    .CarteOrgWarp {
      text-align: left;
      width: 50%;
      border-right: 2px solid #d2d2d2;
      padding-right: 20px;
      padding-bottom: 20px;
      @include tablet {
        width: 100%;
        padding: unset;
        border: unset;
        padding-bottom: 20px;
      }
      h6 {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 15px;
      }
      .uploadMain {
        label {
          width: 100%;
          .PhotoUpload {
            width: 100%;
            height: 170px;
            border: 1px solid #d2d2d2;
            border-radius: 5px;
            @include flex;
            .img1 {
              width: 100%;
              height: 100%;
              object-fit: contain;
              padding: 10px 0px;
            }
          }
        }
      }
      .InputWrap {
        display: flex;
        .FildBox {
          margin-top: 15px;
          width: 100%;
          p {
            font-size: 14px;
            font-weight: 600;
            margin: 0;
            margin-bottom: 10px;
          }
          input {
            border: none;
            width: 100%;
            border-bottom: 1px solid $input-border-color;
          }
          input:focus {
            outline: none;
          }
          select {
            border: none;
            width: 100%;
            border-bottom: 1px solid $input-border-color;
          }
          select:focus {
            outline: none;
          }
        }
        .FildBox:last-child {
          margin-left: 20px;
        }
      }

      .linkInputWrap {
        margin-top: 15px;
        width: 100%;
        p {
          font-size: 14px;
          font-weight: 600;
          margin: 0;
          margin-bottom: 10px;
        }
        input {
          border: none;
          width: 100%;
          border-bottom: 1px solid $input-border-color;
        }
        input:focus {
          outline: none;
        }
      }
    }
    .CarteBannerWrap {
      width: 50%;
      padding-left: 20px;
      @include tablet {
        width: 100%;
        padding: unset;
      }
      h6 {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 15px;
      }
      .uploadMain {
        label {
          width: 100%;
          .PhotoUpload {
            width: 100%;
            height: 125px;
            border: 1px solid #d2d2d2;
            border-radius: 5px;
            @include flex;
            .img1 {
              width: 100%;
              height: 100%;
              object-fit: contain;
              padding: 10px 0px;
            }
          }
        }
      }
      .DescriptionWrap {
        margin-top: 15px;
        width: 100%;
        .TitleMain {
          font-size: 14px;
          font-weight: 600;
          margin: 0;
          margin-bottom: 10px;
        }
        textarea {
          width: 100%;
          max-height: 130px;
          min-height: 130px;
          border: 1px solid #d2d2d2;
          border-radius: 5px;
          resize: none;
          padding: 15px;
          color: #777777;
          font-size: 14px;
        }
        textarea:focus {
          outline: none;
        }
        .errorText {
          color: red !important;
          font-size: 12px;
        }
      }
    }
  }
  .btnWrap {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    button {
      background-color: $pink-color;
      border: none;
      border-radius: 5px;
      color: $White-color;
      padding: 10px;
      min-width: 150px;
    }
  }
}
