@import "../../../App.scss";

.HeaderWrap {
  .ManagmentSection {
    height: 130px;
    background-color: $header-color;
    padding: 20px 30px;
    border: 2px solid #d2d2d2;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include tabletNew {
      flex-wrap: wrap;
      justify-content: unset;
    }

    .CanvasWarp {
      display: none;

      @include tabletNew {
        margin-right: 20px;
      }

      @include tablet {
        display: block;
        margin-bottom: 10px;

        button {
          border: none;
          border-radius: 5px;
          background-color: $footer-color;
          @include flex;
          padding: 10px;

          svg {
            color: $White-color;
          }
        }
      }
    }

    .FilterSection {
      input {
        border-radius: 5px;
        border: 2px solid $fild-border-color;
        background-color: $White-color;
        padding: 7px 20px;
        font-size: 14px;
        width: 100%;
      }

      input:focus {
        outline: none;
      }

      h5 {
        span {
          color: #505050;
          opacity: 0.5;
          cursor: pointer;
          font-weight: 400;
        }

        .Minus {
          padding: 0px 15px;
        }

        font-size: 16px;
        font-weight: 700;
        margin: 0;
        color: $gray-color-font;
      }

      .managementFild {
        display: flex;
        margin-top: 10px;

        .fildBox {
          margin-right: 10px;
          width: 130px;

          button {
            background-color: $footer-color;
            border: 0;
            color: $White-color;
            border-radius: 5px;
            padding: 7px 20px;
          }

          select {
            border-radius: 5px;
            // border: 2px solid $fild-border-color;
            background-color: $White-color;
            padding: 7px 5px;
            font-size: 14px;
            width: 100%;

            // &:focus {
            //   outline: none;
            // }
          }
        }
      }
    }

    .ManagbBnnerSection {
      @include tabletNew {
        margin-top: 10px;
      }

      button {
        background-color: $footer-color;
        border: 0;
        color: $White-color;
        border-radius: 5px;
        padding: 5px 20px;
        margin-right: 10px;

        &:last-child {
          margin-right: unset;
        }

        img {
          margin-left: 10px;
        }
      }
    }
  }
}