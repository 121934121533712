.modal-overlay {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 99999999;
  height: calc(100vh - 75px);
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  
  @media (min-width: 768px) {
    padding: 0;
  }
  
  @media (max-width: 767px) {
    padding: 0 0.5rem;
  }
}

.modal-content {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: max-content;
  max-width: 32rem; // equivalent to max-w-lg
  overflow: auto;
  border-radius: 0.5rem;
  background-color: white;
  padding: 0.5rem 1rem;
  box-shadow: 5.63px 5.63px 8.04px rgba(35, 31, 32, 0.3);
  max-height: calc(100vh - 110px);
  
  @media (min-width: 640px) {
    max-height: calc(100vh - 100px);
  }
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 0.75rem;
  margin-top: 0;
}

.modal-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--secondary-color);
}

.modal-close {
  position: absolute;
  right: 0;
  top: 0.25rem;
  color: var(--stroke-dark);
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  
  .close-icon {
    color: var(--secondary-color);
  }
}

.modal-body {
  margin-top: 1.25rem;
  width: 100%;
}

// Add these variables to your root styles or main SCSS file
:root {
  --secondary-color: #374151; // Example color, adjust as needed
  --stroke-dark: #4B5563; // Example color, adjust as needed
}