@import "../../../../App.scss";
.AddSubscription {
  h3 {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 15px;
  }
  .errorText {
    font-weight: 400 !important;
    color: red !important;
    font-size: 12px !important;
  }
  .AddSubscriptionWrap {
    margin-top: 20px;
    display: flex;
    @include tablet {
      display: unset;
    }
    .LeftSide {
      width: 50%;
      border-right: 2px solid #d2d2d2;
      padding-right: 20px;
      padding-bottom: 20px;
      @include tablet {
        width: 100%;
        padding: unset;
        border: unset;
        padding-bottom: 20px;
      }
      p {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 5px;
      }
      .InputBox {
        width: 100%;
        margin-bottom: 30px;
        margin-right: 10px;
        &:last-child {
          margin-right: unset;
        }
        input {
          background-color: $transparent-color;
          border: none;
          width: 100%;
          color: $th-color;
          border-bottom: 2px solid $input-border-color;
        }
        input:focus {
          outline: none;
        }
      }
      .TextArea {
        margin-bottom: 30px;
      }
      .ToggleWrap {
        width: 50%;
        margin-bottom: 30px;
        .Switch {
          position: relative;
          display: inline-block;
          width: 40px;
          height: 20px;
        }
        .Switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }
        .Slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #d1d1d1;
          transition: 0.4s;
          border-radius: 34px;

          &::before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            top: 2px;
            left: 3px;
            bottom: 2.3px;
            background-color: white;
            transition: 0.4s;
            border-radius: 50%;
          }
        }
        input:checked + .Slider {
          background-color: blue;
          background-color: $pink-color;
        }
        input:checked + .Slider:before {
          transform: translateX(26px);
          background-color: white;
          left: -4px;
        }
      }
      .UploadWrap {
        // width: 100%;
        // margin-bottom: 20px;
        // @include smallmobile {
        //   margin-right: unset;
        //   margin-bottom: 20px;
        // }
        // &:last-child {
        //   margin-right: 0px;
        // }
        p {
          font-size: 14px;
          font-weight: 600;
          margin: 0;
          margin-bottom: 5px;
        }
        .uploadMain {
          label {
            width: 100%;
            .PhotoUpload {
              width: 100%;
              height: 170px;
              border: 1px solid $gray-color;
              border-radius: 5px;
              @include flex;
              .img1 {
                width: 100%;
                height: 100%;
                object-fit: contain;
                padding: 10px 0px;
              }
            }
          }
        }
      }
      .FlexWrap {
        display: flex;
        .InputBox {
          width: 50%;
        }
      }
    }
    .RigthSide {
      width: 50%;
      padding-left: 20px;
      @include tablet {
        width: 100%;
        padding: unset;
      }
      .accordion {
        margin-bottom: 10px;

        &.open {
          .AccordionHeader.arrow {
            transform: rotate(180deg);
          }
          .AccordionContent {
            display: block;
            .tab_content {
              transition: all 0.35s;
              border-left: 1px solid $input-border-color;
              padding-left: 10px;
              .InputBox {
                width: 100%;
                margin-bottom: 10px;
                p {
                  font-size: 14px;
                  font-weight: 600;
                  margin: 0;
                  margin-bottom: 5px;
                }
                input {
                  background-color: $transparent-color;
                  border: none;
                  width: 100%;
                  color: $th-color;
                  border-bottom: 2px solid $input-border-color;
                }
                input:focus {
                  outline: none;
                }
              }
              .PriodicityWrap {
                display: flex;
                margin-bottom: 10px;
                .DropDownBox {
                  width: 50%;
                  margin-right: 10px;
                  p {
                    font-size: 14px;
                    font-weight: 600;
                    margin: 0;
                    margin-bottom: 5px;
                  }
                  select {
                    background-color: $transparent-color;
                    border: none;
                    width: 100%;
                    color: $th-color;
                    border-bottom: 2px solid $input-border-color;
                  }
                  select:focus {
                    outline: none;
                  }
                }
                .SelectTimeWrpa {
                  p {
                    font-size: 14px;
                    font-weight: 600;
                    margin: 0;
                    margin-bottom: 5px;
                  }
                  .CountSection {
                    display: flex;
                    align-items: center;
                    .CountWrap {
                      display: flex;
                      margin-right: 20px;
                      input {
                        background-color: $transparent-color;
                        border: none;
                        width: 30px;
                        color: $th-color;
                        border-bottom: 2px solid $input-border-color;
                        margin-right: 10px;
                      }
                      input:focus {
                        outline: none;
                      }
                      .IncreaseAndDecrease {
                        display: flex;
                        flex-direction: column;
                        .IncreaseDecreaseBtnWrap {
                          height: 10px;
                          width: 10px;
                          @include flex;
                          border-radius: 50%;
                          border: 1px solid $input-border-color;
                          font-size: 11px;
                          cursor: pointer;
                          margin-top: 5px;
                        }
                      }
                    }
                    .CountText {
                      font-size: 14px;
                      font-weight: normal;
                      color: $th-color;
                      margin: 0;
                    }
                  }
                }
              }
              .DiscountsWrap {
                p {
                  font-size: 14px;
                  font-weight: 600;
                  margin: 0;
                  margin-bottom: 2px;
                }
                .Smalltext {
                  font-size: 7px;
                  color: $th-color;
                  margin-bottom: 10px;
                }
                .DiscounttsSection {
                  width: 100%;
                  height: 220px;
                  overflow: auto;
                  border: 2px solid $input-border-color;
                  background-color: $header-color;
                  border-radius: 5px;
                  padding: 10px;
                  .DiscounttOptionWrap {
                    cursor: move;
                    padding: 10px 15px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: $White-color;
                    border: 2px solid $input-border-color;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    &:last-child {
                      margin-bottom: unset;
                    }
                    p {
                      font-size: 13px;
                      margin: 0;
                      color: $th-color;
                      font-weight: normal;
                    }
                    .CheckboxWrap {
                      display: flex;
                      .radioCustom {
                        opacity: 0;
                        position: absolute;
                      }
                      .radioCustom,
                      .radioCustomLabel {
                        display: inline-block;
                        vertical-align: middle;
                        cursor: pointer;
                      }
                      .radioCustomLabel {
                        position: relative;
                      }
                      .radioCustom + .radioCustomLabel:before {
                        content: "";
                        background: #fff;
                        border: 1px solid $gray-color;
                        display: inline-block;
                        width: 6px;
                        height: 6px;
                        padding: 5px;
                        border-radius: 2px;
                        text-align: center;
                        margin-right: 10px;
                      }

                      .radioCustom:checked + .radioCustomLabel:before {
                        background: $pink-color;
                      }
                      .removeWrap {
                        width: 22px;
                        height: 22px;
                        border-radius: 50%;
                        border: 2px solid $input-border-color;
                        font-size: 25px;
                        @include flex;
                        cursor: pointer;
                      }
                    }
                  }
                }
                .AddDiscount {
                  width: 100%;
                  border-bottom: 2px solid $input-border-color;
                  margin-bottom: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 10px;
                  input {
                    background-color: $transparent-color;
                    border: none;
                    width: 100%;
                    color: $th-color;
                  }
                  input:focus {
                    outline: none;
                  }

                  .addWrap {
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    border: 2px solid $input-border-color;
                    font-size: 25px;
                    @include flex;
                    cursor: pointer;
                  }
                }
              }
              .FlexWrap {
                display: flex;
                .DateWrap {
                  width: 50%;
                  margin-right: 10px;
                }
                .OrderByWrap {
                  .ToggleWrap {
                    margin-bottom: 10px;
                    p {
                      font-size: 14px;
                      font-weight: 600;
                      margin: 0;
                      margin-bottom: 5px;
                    }
                    .Switch {
                      position: relative;
                      display: inline-block;
                      width: 40px;
                      height: 20px;
                    }
                    .Switch input {
                      opacity: 0;
                      width: 0;
                      height: 0;
                    }
                    .Slider {
                      position: absolute;
                      cursor: pointer;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      background-color: #d1d1d1;
                      transition: 0.4s;
                      border-radius: 34px;

                      &::before {
                        position: absolute;
                        content: "";
                        height: 16px;
                        width: 16px;
                        top: 2px;
                        left: 3px;
                        bottom: 2px;
                        background-color: white;
                        transition: 0.4s;
                        border-radius: 50%;
                      }
                    }
                    input:checked + .Slider {
                      background-color: blue;
                      background-color: $pink-color;
                    }
                    input:checked + .Slider:before {
                      transform: translateX(26px);
                      background-color: white;
                      left: -4px;
                    }
                  }
                }
              }
            }
          }
        }
        .AccordionHeader {
          display: flex;
          align-items: center;
          .NameDiscountWrap {
            display: flex;
            .DiscountInput {
              background-color: $transparent-color;
              border: none;
              border-bottom: 2px solid $input-border-color;
              font-size: 14px;
              font-weight: 600;
            }
            input:focus {
              outline: none;
            }
            img {
              cursor: pointer;
            }
          }
          .RemoveOfferWrap {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            border: 2px solid $input-border-color;
            font-size: 25px;
            @include flex;
            cursor: pointer;
            margin-left: 20px;
          }
          .arrow {
            transition: transform 0.3s ease;
          }
        }

        .AccordionContent {
          display: none;
          padding: 10px 0px;
          background-color: #fff;
        }
      }
      .AddDiscountMainWrap {
        display: flex;
        align-items: center;
        p {
          font-size: 14px;
          font-weight: 600;
          margin: 0;
          margin-right: 20px;
        }
        .customdropdown {
          .dropdown {
            position: relative;
            display: inline-block;
            cursor: pointer;

            .dropdowntoggle {
              padding: 10px;
              border-radius: 4px;
              background-color: #fff;
            }

            .arrow {
              margin-left: 5px;
            }

            &.open {
              .dropdowntoggle {
                border-color: #999;
              }
            }

            .dropdownmenu {
              position: absolute;
              bottom: 50%;
              left: 0;
              width: 100%;
              max-height: 200px;
              overflow-y: auto;
              border: 1px solid #ccc;
              border-top: none;
              border-radius: 4px;
              background-color: #fff;
              padding: 0;
              margin-top: 5px;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              z-index: 9999;

              li {
                padding: 10px;

                &:hover {
                  background-color: #f5f5f5;
                }
              }
            }
          }
        }
        .addWrap {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          border: 2px solid $input-border-color;
          font-size: 25px;
          @include flex;
          cursor: pointer;
        }
      }
    }
  }
  .btnWrap {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
    button {
      background-color: $pink-color;
      border: none;
      border-radius: 5px;
      color: $White-color;
      padding: 10px 50px;
    }
  }
}
.customSelect {
  position: relative;

  .options {
    position: absolute;
    bottom: 100%;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      background-color: $White-color;
      border-radius: 5px;
      max-width: 300px;
      border: 1px solid $input-border-color;
      padding: 10px 15px;
      li {
        font-size: 14px;
        font-weight: 400;
        border-bottom: 1px solid $input-border-color;
        padding: 5px 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
