@import "../../../App.scss";
.SideBarSection {
  padding-top: 10px;
  position: relative;
  /* height: 100vh; */
  min-width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 57px);
  .SideBarWrap {
    margin-left: 21px;
    padding-right: 0.5rem;
    // overflow-y: auto;
    .LogoMain {
      @include flex;
      margin-bottom: 20px;
      img {
        width: 150px;
      }
    }
    .UserInfo {
      max-width: 270px;
      background-color: $darkPink-color;
      border-radius: 15px;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .UserName {
        span {
          font-size: 14px;
          font-weight: 300;
          color: $White-color;
        }
        p {
          font-size: 18px;
          font-weight: 500;
          color: $White-color;
          margin: 0;
        }
      }
      .UserPosition {
        p {
          font-size: 14px;
          font-weight: 400;
          color: $White-color;
          margin: 0;
        }
      }
    }
    .SidebarLinkWrap {
      margin-bottom: 50px;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          color: $gray-color-font;
          font-size: 14px;
          font-weight: 500;
          padding: 15px 0px;
          cursor: pointer;
          display: flex;
          img,
          svg {
            width: 17px;
            height: 17px;
            margin-right: 25px;
          }
        }
      }
    }
  }
  .VersionSection {
    @include flex;
    background-color: $footer-color;
    padding: 15px 0px;
    position: fixed;
    bottom: 0;
    max-width: 300px;
    width: 100%;
    p {
      font-size: 18px;
      font-weight: 500;
      color: $White-color;
      margin: 0;
    }
  }
}
.icons {
  transition: all 0.5s ease;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
}

.sidebarLinkWrap li.active .icon {
  transition: transform 0.3s ease;
}

.sidebarLinkWrap li.active .icon:hover {
  transform: scale(1.2);
}
