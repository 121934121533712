@import "../../../../App.scss";
.EditMainContact {
  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 15px;
  }
  .EditMainContactWrap {
    display: flex;
    @include tablet {
      display: unset;
    }
    .UserInfoSection {
      width: 30%;
      border-right: 2px solid #d2d2d2;
      padding-right: 20px;
      padding-bottom: 20px;
      @include tablet {
        width: 100%;
        padding: unset;
        border: unset;
        padding-bottom: 20px;
      }
      .UserInfoWrap {
        .UserPhoto {
          margin-bottom: 20px;
          width: 170px;
          height: 170px;
          filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.16));
          img {
            width: 100%;
            height: 100%;
          }
        }
        .UserDetailWrap {
          h4 {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
            margin-bottom: 10px;
          }
          p {
            font-size: 14px;
            font-weight: 400;
            color: $userListing-color;
            margin: 0;
            margin-bottom: 10px;
          }
        }
        button {
          background-color: $userListing-color;
          border: none;
          border-radius: 5px;
          color: $White-color;
          padding: 10px 20px;
        }
      }
    }
    .ChangeContactSection {
      width: 70%;
      padding-left: 20px;
      @include tablet {
        width: 100%;
        padding: unset;
      }
      h6 {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        margin-bottom: 15px;
        text-transform: capitalize;
      }
      .ChangeMainContactWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        @include desktopsmall {
          flex-wrap: wrap;
        }
        .WithSameOrg {
          display: flex;
          align-items: center;
          border-bottom: 2px solid $input-border-color;
          @include desktopsmall {
            margin-bottom: 20px;
          }
          p {
            font-size: 14px;
            font-weight: 400;
            margin: 0;
            margin-right: 20px;
          }
          .radioCustom {
            opacity: 0;
            position: absolute;
          }
          .radioCustom,
          .radioCustomLabel {
            display: inline-block;
            vertical-align: middle;
            margin-right: 7px;
            cursor: pointer;
          }
          .radioCustomLabel {
            position: relative;
          }
          .radioCustom + .radioCustomLabel:before {
            content: "";
            background: #fff;
            border: 1.5px solid #595f72;
            display: inline-block;
            vertical-align: middle;
            border-radius: 2px;
            padding: 7px;
            text-align: center;
          }
          .radioCustom + .radioCustomLabel:before {
            border-radius: 50%;
          }
          .radioCustom:checked + .radioCustomLabel:before {
            background: $pink-color;
            box-shadow: inset 0px 0px 0px 2px #fff;
          }
        }
        .SearchBranch {
          input {
            border: 2px solid $fild-border-color;
            border-radius: 5px;
            padding: 5px 10px;
          }
          input:focus {
            outline: none;
          }
          button {
            background-color: $footer-color;
            border: none;
            border-radius: 5px;
            color: $White-color;
            padding: 8px 10px;
            margin-left: 10px;
            img {
              margin-left: 10px;
              width: 14px;
            }
          }
        }
      }
      .ChangeContactTable {
        border-radius: 5px;
        border: 1px solid #d2d2d2;
        height: 170px;
        overflow: auto;
        table {
          width: 100%;
        }
        tr {
          th {
            font-size: 14px;
            font-weight: 500;
            color: $th-color;
            padding: 5px 20px;
            pointer-events: none;
          }
          td {
            font-size: 14px;
            font-weight: 500;
            color: $th-color;
            padding: 5px 20px;
          }
          .normaltd {
            font-size: 14px;
            font-weight: 500;
            color: $th-color;
            padding: 5px 20px;
          }
          .selectedtd {
            background-color: $footer-color;
            font-size: 14px;
            font-weight: 500;
            color: $White-color;
            padding: 5px 20px;
          }
          &:hover {
            background-color: $footer-color;
            td {
              color: $White-color;
              cursor: pointer;
            }
          }
        }
      }
      .ChangeContactBtn {
        margin-top: 35px;
        display: flex;
        justify-content: flex-end;
        button {
          background-color: $footer-color;
          border: none;
          border-radius: 5px;
          color: $White-color;
          padding: 10px;
          img {
            margin-left: 10px;
          }
        }
      }
      .EditbtnWrap {
        margin-top: 35px;
        display: flex;
        justify-content: flex-end;
        button {
          background-color: $pink-color;
          border: none;
          border-radius: 5px;
          color: $White-color;
          padding: 10px 50px;
        }
      }
    }
  }
}
