@import "../../App.scss";
.CountriesMain {
  position: relative;
  height: 100vh;
  width: 100%;
  .CountriesSection {
    width: 100%;
    height: calc(100% - 187px);
    padding: 0px 40px;
    overflow-x: auto;
    table {
      width: 100%;
      @media screen and (max-width: 1024px) {
        width: 950px;
      }
    }
    tr {
      th {
        padding: 20px 10px;
        .PaymentOption {
          padding: 5px 0 !important;
        }
        color: $userListing-color;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 2px solid #e6eaf0;
      }
      th:last-child {
        border: none;
      }
      td {
        padding: 20px 10px;
        color: $userListing-color;
        font-size: 14px;
        font-weight: 400;
        border-bottom: 2px solid #e6eaf0;
        width: 50%;
        input {
          border-radius: 5px;
          border: 2px solid #e9e9e9;
          background-color: #ffffff;
          font-size: 14px;
          width: 50px;
        }
        input:focus {
          outline: none;
        }
        p {
          margin: 0;
          padding: 0;
        }
        .Switch {
          position: relative;
          display: inline-block;
          width: 40px;
          height: 20px;
        }
        .Switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }
        .Slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #d1d1d1;
          transition: 0.4s;
          border-radius: 34px;

          &::before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            left: 3px;
            top: 2px;
            background-color: $White-color;
            transition: 0.4s;
            border-radius: 50%;
          }
        }
        input:checked + .Slider {
          background-color: blue;
          background-color: $pink-color;
        }
        input:checked + .Slider:before {
          transform: translateX(26px);
          background-color: $White-color;
          left: -4px;
        }
      }
      .userEditor {
        border: none;
        cursor: pointer;
        position: relative;
        text-align: center;
        padding: 0px 5px;
        width: 0%;
        .EditorWrap {
          position: absolute;
          right: 0;
          text-align: left;
          margin-top: 10px;
          background-color: $White-color;
          width: 260px;
          filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.16));
          z-index: 1;
          display: block;
          padding: 20px;
          border-radius: 5px;
          ul {
            padding: 0;
            margin: 0;
            list-style: none;
            li {
              cursor: pointer;
              font-size: 14px;
              font-weight: 400;
              border-bottom: 1px solid $input-border-color;
              padding: 5px 0px;
              color: $userListing-color;
            }
            li:last-child {
              cursor: pointer;
              border: none;
            }
          }
        }
      }
      .LoaderWrap {
        text-align: center;
        border: unset;
        height: 50vh;
      }
    }
  }
}
