@import "../../../../App.scss";
.CreateUserMain {
  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    margin: 0;
    margin-bottom: 15px;
  }
  .errorText{
		color:red !important;
		font-size: 12px !important;
    font-weight: 500 !important;
	  }
  .CarteUserWarp {
    text-align: left;
    h6 {
      font-size: 14px;
      font-weight: 500;
      margin: 0;
      margin-bottom: 15px;
      
    }
    .uploadMain {
      label {
        width: 100%;
        .PhotoUpload {
          width: 100%;
          height: 170px;
          border: 1px solid $gray-color;
          border-radius: 5px;
          @include flex;
          .img1 {
            width: 100%;
            height: 100%;
            object-fit: contain;
            padding: 10px 0px;
          }
        }
      }
    }
    .FildBox {
      margin-top: 15px;
      p {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        margin-bottom: 10px;
      }
      input {
        border: none;
        width: 100%;
        border-bottom: 1px solid $input-border-color;
      }
      input:focus {
        outline: none;
      }
    }
    .btnWrap {
      margin-top: 15px;
      display: flex;
      justify-content: flex-end;
      button {
        background-color: $pink-color;
        border: none;
        border-radius: 5px;
        color: $White-color;
        padding: 10px;
      }
    }
  }
}
