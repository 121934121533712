@import "../../App.scss";

.BannerMain {
  position: relative;
  height: 100vh;
  width: 100%;

  .BannerListingSection {
    width: 100%;
    height: calc(100% - 233px);
    padding: 0px 20px;
    overflow-x: auto;

    table {
      width: 100%;

      @media screen and (max-width: 1024px) {
        width: 950px;
      }
    }

    tr {
      .LoaderWrap {
        text-align: center;
        border: unset;
        height: 50vh;
      }

      th {
        padding: 20px 0px;
        color: $userListing-color;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 2px solid #e6eaf0;
      }

      td {
        padding: 20px 0;
        color: $userListing-color;
        font-size: 14px;
        font-weight: 400;
        border-bottom: 2px solid #e6eaf0;
      }

      .BannerNumber {
        font-size: 12px;
      }

      .BannerEditor {
        border: none;
        cursor: pointer;
        position: relative;
        text-align: center;
        padding: 0px 5px;

        .EditorWrap {
          position: absolute;
          right: 0;
          text-align: left;
          margin-top: 10px;
          background-color: $White-color;
          width: 260px;
          filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.16));
          z-index: 1;
          display: block;
          padding: 20px;
          border-radius: 5px;

          ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
              cursor: pointer;
              font-size: 14px;
              font-weight: 400;
              border-bottom: 1px solid $input-border-color;
              padding: 5px 0px;
              color: $userListing-color;
            }

            li:last-child {
              cursor: pointer;
              border: none;
            }
          }
        }
      }
    }
  }
}