@import "../../App.scss";
.LanguageMain {
  position: relative;
  height: 100vh;
  width: 100%;
  .SystemManagementSection {
    width: 100%;
    height: calc(100% - 170px);
    padding: 0px 20px;
    overflow-x: auto;
    .SystemManagementWrap {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      @include desktop {
        grid-template-columns: repeat(4, 1fr);
      }
      @include desktoplargescreen {
        grid-template-columns: repeat(5, 1fr);
      }
      .SectionWrap {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 15px 0px;
        .IconWrap {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: $userListing-color;
          @include flex;
          margin-right: 15px;
          padding: 10px;
          img {
            height: 100%;
            width: 100%;
          }
          svg {
            color: #fff;
            height: 100%;
            width: 100%;
          }
        }
        p {
          margin: 0;
        }
      }
    }
  }
}
