@import "../src/assets/style/common.scss";
@import "../src/assets/style/mixin.scss";
@import "../src/assets/style/variable.scss";

.row {
  margin: 0 !important;
  padding: 0 !important;
}
.col {
  padding: 0 !important;
}
.swal2-container {
  z-index: 1050 !important; /* Make sure it's higher than any backdrop */
}

.modal-backdrop {
  opacity: 0.5;
}
// body{
//   --ck-z-default: 100;
// 	--ck-z-modal: calc( var(--ck-z-default) + 999 );
// }
// :root {
//   --ck-z-default: 2000;
//   // --ck-z-panel: calc( var(--ck-z-default) + 999 );
// }
.ck-balloon-panel{
  z-index:1051 !important;
} 
.ck.ck-reset_all,
.ck.ck-content,
.ck.ck-editor__main,
.ck.ck-balloon-panel,
.ck.ck-dropdown {
  z-index: 1051 !important;
}

.modal-open .ck-balloon-panel,
.modal-open .ck-dropdown {
  z-index: 1051 !important;
}
// .offcanvas{
//   margin: 0 !important;
//   padding: 0 !important;
//   // background-color: red !important;
// }

// .offcanvas-header{
//   margin: 0 !important;
//   padding: 0 !important;
// }
// .offcanvas-body{
//   margin: 0 !important;
//   padding: 0 !important;
// }

// .offcanvas-header{
//   button{
//     margin-top: 12px !important;
//     margin-right: 12px !important;
//   }
// }
