@import "../../App.scss";
.ListingMain {
  position: relative;
  height: 100vh;
  width: 100%;
  .ListingSection {
    width: 100%;
    height: calc(100% - 292px);
    @media screen and (max-width: 1574px) {
      height: calc(100% - 340px);
    }
    @include desktopsmall {
      height: calc(100% - 342px);
    }
    padding: 0px 10px;
    overflow-x: auto;
    table {
      width: 100%;
      min-width: 1200px;
      overflow-x: scroll;
      @media screen and (max-width: 1024px) {
        width: 950px;
      }
    }
    .InvisibleCard > td:not(:last-child) {
      opacity: 40%;
    }
    tr {
      .LoaderWrap {
        text-align: center;
        border: unset;
        height: 40vh;
      }
      th {
        padding: 20px 0px;
        color: $userListing-color;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 2px solid #e6eaf0;
      }
      td {
        padding: 20px 0;
        color: $userListing-color;
        font-size: 14px;
        font-weight: 400;
        border-bottom: 2px solid #e6eaf0;

        svg {
          font-size: 20px;
          cursor: pointer;
        }
      }
      td:nth-child(2) {
        border-left: 5px solid $White-color;
      }
      td:last-child {
        border-right: 5px solid $White-color;
      }
      .EditorWrap {
        opacity: 1;
        text-align: left;
        position: absolute;
        right: 0;
        margin-top: 10px;
        background-color: $White-color;
        width: 260px;
        filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.16));
        z-index: 1;
        display: block;
        padding: 20px;
        border-radius: 5px;
        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          li {
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            border-bottom: 1px solid $input-border-color;
            padding: 5px 0px;
            color: $userListing-color;
          }
          li:last-child {
            border: none;
            color: $blue-color;
          }
        }
      }
      .NoDataText {
        text-align: center;
        border: none;
        font-size: 32px;
        font-weight: 600;
      }
      .selectOrg {
        border: none;
        padding: 0px 10px;
        position: relative;
        .radioCustom {
          opacity: 0;
          position: absolute;
        }
        .radioCustom,
        .radioCustomLabel {
          display: inline-block;
          vertical-align: middle;
          cursor: pointer;
        }
        .radioCustomLabel {
          position: relative;
        }
        .radioCustom + .radioCustomLabel:before {
          content: "";
          background: #fff;
          border: 2px solid $gray-color;
          display: inline-block;
          width: 6px;
          height: 6px;
          padding: 5px;
          border-radius: 2px;
          text-align: center;
        }
        .radioCustom:checked + .radioCustomLabel:before {
          background: $pink-color;
        }
      }
      .userEditor {
        border: none;
        cursor: pointer;
        position: relative;
        padding: 0px 5px;
        text-align: center;
      }
    }
    .active {
      td:nth-child(2) {
        border-left: 5px solid $pink-color;
      }
      td:last-child {
        border-right: 5px solid $pink-color;
      }
    }
  }
}
