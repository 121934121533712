@import "../../../App.scss";
.ViewOrgSubscriptionPlanMain {
  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 15px;
  }
  .ViewOrgSubscriptionPlanWrap {
    max-height: 350px;
    min-height: 350px;
    border-radius: 5px;
    border: 1px solid #d2d2d2;
    overflow: auto;
    table {
      width: 100%;
    }
    .default {
      td {
        font-size: 14px;
        font-weight: 500;
        color: $th-color;
        padding: 5px 20px;
      }
    }
    .selected {
      background-color: $footer-color;
      td {
        font-size: 14px;
        font-weight: 500;
        color: $th-color;
        padding: 5px 20px;
        color: $White-color;
        cursor: pointer;
      }
    }
    tr {
      th {
        font-size: 14px;
        font-weight: 500;
        color: $th-color;
        padding: 5px 20px;
      }
      th {
        pointer-events: none;
      }
      &:hover {
        background-color: $footer-color;
        td {
          color: $White-color;
          cursor: pointer;
        }
      }
      .selectStyle {
        background-color: $footer-color;
        font-size: 14px;
        font-weight: 500;
        color: $White-color;
        cursor: pointer;
        padding: 5px 20px;
      }
    }
    .NoData {
      pointer-events: none;
      .LoaderWrap {
        text-align: center;
        border: unset;
        height: 50vh;
      }
    }
  }

  .AddUseAsSection {
    display: flex;
    align-items: center;
    margin-top: 15px;
    justify-content: flex-end;
    button {
      background-color: $pink-color;
      border: none;
      border-radius: 5px;
      color: $White-color;
      padding: 10px;
      margin-right: 10px;
    }
  }
}
