@import "../../App.scss";
.main {
	height: 100vh;
	position: relative;
	.MainLayoutOrg {
		display: flex;
		height: calc(100% - 139px) !important;
		border: 1px solid #d2d2d2;
		overflow-x: auto;
		.OrgDetails {
			width: 30%;
			box-sizing: content-box;
			.basicOrgDetails {
				border-right: 1px solid #d2d2d2;
				padding: 4%;
				height: fit-content;
			}
			.orgLogo {
				height: 330px;
				width: 100%;
				position: relative;
				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 0.4;
					background: linear-gradient(
						0deg,
						rgba(0, 0, 0, 0.71) 0%,
						rgba(8, 8, 8, 0.3) 100%
					);
					opacity: 0.6;
				}
				.IconWrap {
					position: absolute;
					width: 92%;
					top: 10px;
					left: 10px;
					display: flex;
					justify-content: space-between;
					svg {
						color: white;
						font-size: 20px;
						cursor: pointer;
					}
				}
				.UplodLinks {
					position: absolute;
					bottom: 10px;
					left: 6px;
					p {
						font-size: 14px;
						color: $White-color;
						margin-bottom: 5px;
						span {
							font-family: $Poppins-SemiBold;
							cursor: pointer;
						}
					}
				}
				img {
					height: 100%;
					width: 100%;
				}

				@include tabletNew {
					width: 100%;
					height: 200px;
				}
				// @include desktopMed {
				//   width: 100%;
				//   height: 220px;
				// }
			}
			.orgName {
				margin: 3% 0;
				margin-top: 5%;
				font-family: $Poppins-SemiBold;
				font-size: 34px;
				line-height: 51px;
				color: $footer-color;
				-ms-word-break: break-word;
				word-break: break-word;
				white-space: pre-wrap;
				@include tabletNew {
					font-size: 20px;
				}
				@include desktopMed {
					font-size: 22px;
				}
				@include desktoplarge {
					font-size: 28px;
				}
			}
			.OtherDetails {
				p {
					margin: 0;
					font-size: $Poppins-Regular;
					font-size: 14px;
					color: $userListing-color;
					@include tabletNew {
						font-size: 12px;
					}
					@include desktopMed {
						font-size: 13px;
					}
				}
				.email {
					margin-bottom: 2%;
				}
				.SomeSpacing {
					margin-bottom: 2%;
				}
				.SomeSpacingFixedWidth {
					margin-bottom: 2%;
					width: 50%;
				}
				.ItalicText {
					font-family: $Poppins-Italic;
					font-weight: 400 !important;
				}
				.Flex {
					// @include flex;
					display: flex;
					justify-content: space-between;
				}
			}
			.EditIcon {
				height: 20px;
				min-width: 22px;
				width: 100%;
				display: flex;
				justify-content: flex-end;
				align-items: flex-end;

				// img {
				//   height: 100%;
				//   width: 100%;
				// }
			}
			.OrganisationInfo {
				// border-top: 1px solid #d2d2d2;
			}
		}
		.QuickOptAndInvoiceContainer {
			display: flex;
			width: 100%;
			// background-color: red;
			height: fit-content;
			.QuickOptionContainer {
				height: fit-content;
				border-bottom: 1px solid #d2d2d2;
				border-right: 1px solid #d2d2d2;
				padding: 3% 3% 7% 3%;
				width: 35%;
				.BoldMedText {
					font-family: $Poppins-BoldItalic;
					font-size: 24px;
					line-height: 35px;
					color: $footer-color;
					@include tabletNew {
						font-size: 16px;
					}
					@include desktopMed {
						font-size: 18px;
					}
				}
				.SimpleGreyText {
					font-size: 14px;
					line-height: 21px;
					color: $userListing-color;
					@include tabletNew {
						font-size: 12px;
					}
					@include desktopMed {
						font-size: 13px;
					}
					p {
						cursor: pointer;
					}
				}
			}
			.UserLogginLayout {
				border-left: 1px solid #d2d2d2;
				border-bottom: 1px solid #d2d2d2;
				width: 70%;
				padding: 30px;
				@include tabletNew {
					padding: 20px;
				}
				@include desktopMed {
					padding: 25px;
				}
				.TextAndActionBtn {
					display: flex;
					justify-content: space-between;
					align-items: center;
					p {
						font-size: 16px;
						line-height: 25px;
						font-weight: 600;
						color: $userListing-color;
						span {
							opacity: 0.6;
						}
						@include tabletNew {
							font-size: 14px;
						}
						@include desktopMed {
							font-size: 15px;
						}
					}

					button {
						background-color: $userListing-color;
						color: $White-color;
						border-radius: 5px;
						border: none;
						outline: none;
						padding: 10px 20px;
						font-family: $Poppins-Regular;
						font-size: 16px;
						@include tabletNew {
							font-size: 11px;
							padding: 1% 2% !important;
						}
						img {
							margin-left: 10px;
						}
					}
				}
				.LoginHistroyLayout {
					padding: 10px 0;
					.logginContentRow {
						display: flex;

						justify-content: space-between;
						font-size: 14px;
						line-height: 21px;
						color: $userListing-color;
						@include tabletNew {
							font-size: 12px;
						}
					}
				}
			}
		}
		.UsersAndOrgBranches {
			display: flex;

			.UsersContainer {
				width: 50%;
				border-right: 1px solid #d2d2d2;
				padding: 3%;

				.HeaderAndSearchBar {
					display: flex;
					margin-bottom: 10px;
					width: 100%;
					align-items: center;
					justify-content: space-between;
					p {
						font-size: 16px;
						line-height: 25px;
						font-weight: 600;
						margin: 0;
						width: 30%;
						color: $footer-color;
						@include tabletNew {
							font-size: 12px;
						}
						@include desktopMed {
							font-size: 13px;
						}

						span {
							opacity: 0.6;
						}
					}

					.UsersDetails {
						table {
						}
					}
				}
				.MainContactCard {
					display: flex;
					p {
						font-size: 14px;
						line-height: 21px;
						color: $userListing-color;
					}
					.MainUserImg {
						height: 98px;
						width: 100px;

						img {
							height: 100%;
							width: 100%;
							object-fit: contain;
							box-shadow: 3px 3px 10px #00000029;
						}
					}
					.MainContactDetails {
						margin-left: 3%;

						.BoldMedText {
							font-family: $Poppins-SemiBold;
						}
					}
				}
				.MainContactOtherListing {
					margin: 3% 0;
					.simpleTextStyle {
						font-size: 14px;
						line-height: 21px;
						color: $userListing-color;
						margin: 0;
						margin-bottom: 1%;
					}
					.BlueColorText {
						color: #3151b5;
						font-family: $Poppins-SemiBold;
					}
				}
				.editIcon {
					display: flex;
					justify-content: end;
					align-items: end;
				}
			}
			.OrganisatonBranchesContainer {
				width: 50%;
				padding: 3%;
				.HeaderOrgBranches {
					p {
						font-size: 16px;
						line-height: 25px;
						font-weight: 600;
						margin: 0;
						width: 30%;
						color: $footer-color;
						@include tabletNew {
							font-size: 12px;
						}
						@include desktopMed {
							font-size: 13px;
						}

						span {
							opacity: 0.6;
						}
					}
				}
				.OrgLogoAndName {
					margin: 3% 0;
					p {
						font-size: 14px;
						color: $userListing-color;
						line-height: 21px;
						margin: 1% 0;
					}
					.OrgLogo {
						height: 126px;
						width: 116px;
						@include desktopMed {
							height: 98px;
							width: 92px;
						}

						img {
							object-fit: contain;
							height: 100%;
							width: 100%;
						}
					}
				}
			}
		}
		.PriceHistoryAndAddedLater {
			width: 100%;
			border-top: 1px solid #d2d2d2;
			border-left: 1px solid #d2d2d2;
			border-bottom: 1px solid #d2d2d2;

			display: flex;
			.PriceHistory {
				width: 60%;
				height: 100%;
				padding: 3%;
				.TextAndActionBtn {
					display: flex;
					justify-content: space-between;
					p {
						font-size: 16px;
						line-height: 25px;
						font-weight: 600;
						color: $userListing-color;
						span {
							opacity: 0.6;
						}
						@include tabletNew {
							font-size: 14px;
						}
						@include desktopMed {
							font-size: 15px;
						}
					}
					button {
						background-color: $userListing-color;
						color: $White-color;
						border-radius: 5px;
						border: none;
						outline: none;
						padding: 1.5% 2.5%;
						font-family: $Poppins-Regular;
						font-size: 16px;
						@include tabletNew {
							font-size: 11px;
							padding: 1% 2% !important;
						}
						img {
							margin-left: 10px;
						}
					}
				}
				.LoginHistroyLayout {
					padding: 2% 0;
					margin: 2% 0;

					width: 55%;
					.logginContentRow {
						p {
							margin: 0;
							margin: 3% 0;
						}
						display: flex;
						border-bottom: 2px solid #d2d2d2;
						justify-content: space-between;
						font-size: 14px;
						line-height: 21px;
						color: $userListing-color;
						@include tabletNew {
							font-size: 12px;
						}
					}
				}
			}
			.WillBeAdded {
				width: 40%;
				background-color: #000000;
				height: auto;
				opacity: 0.6;
			}
		}
	}
}
.HeadStylingText {
	font-size: 14px;
	line-height: 21px;
	color: $userListing-color;
	font-weight: bold;
	@include tabletNew {
		font-size: 12px;
	}
	@include desktopMed {
		font-size: 13px;
	}
}

.DataText {
	font-size: 14px;
	line-height: 21px;
	color: $userListing-color;
	font-weight: 200;
	@include tabletNew {
		font-size: 12px;
	}
	@include desktopMed {
		font-size: 13px;
	}
}
.TableHeadStyling {
	display: flex;
	justify-content: space-between;
	border-bottom: 2px solid;
	border-bottom-color: rgba(210, 210, 210, 0.2);
	padding: 2% 0;
}
.flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
