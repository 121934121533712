@import "../../../../App.scss";
.EditMediaFrom {
  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 15px;
  }
  .UploadFileSection {
    .UploadWrap {
      width: 100%;
      p {
        font-size: 16px;
        font-family: $Poppins-SemiBold;
        line-height: 14px;
        color: black;
      }
      .MainWrap {
        label {
          width: 100%;
          .PhotoUpload {
            width: 100%;
            height: 170px;
            border: 1px solid $gray-color;
            border-radius: 5px;
            padding: 10px;
            overflow: auto;
            @include flex;
            .AllImgWrap {
              display: flex;
              flex-wrap: wrap;
            }
          }
          .DisplayAfterImageUpload {
            width: 100%;
            height: 170px;
            border: 1px solid $gray-color;
            border-radius: 5px;
            padding: 10px;
            overflow: auto;
            .AllImgWrap {
              display: flex;
              flex-wrap: wrap;
              .UploadImg {
                position: relative;
                img {
                  width: 60px;
                  height: 60px;
                  border-radius: 5px;
                  margin-right: 10px;
                  margin-bottom: 10px;
                }
                .VideoWrap {
                  position: relative;
                  video {
                    width: 60px;
                    height: 60px;
                    border-radius: 5px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    object-fit: cover;
                  }
                  .videoPlayButton {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-62%, -75%);
                    img {
                      width: 25px;
                      height: 25px;
                      margin: 0 auto;
                    }
                  }
                }
                span {
                  cursor: pointer;
                  position: absolute;
                  top: -3px;
                  right: 12px;
                  svg {
                    background-color: $White-color;
                    border-radius: 50%;
                    padding: 3px;
                  }
                }
              }
              .AfterUploadImageIcon {
                width: auto;
                height: auto;
                margin-right: 10px;
                margin-bottom: 10px;
              }
            }
          }
        }
        input {
          padding: 15px;
          border-radius: 5px;
          background-color: $transparent-color;
          border: none;
          width: 100%;
        }
        input:focus {
          outline: none;
        }
      }
    }
    .UploadContent {
      display: flex;
      width: 100%;
      @include smallmobile {
        flex-wrap: wrap;
      }
      .MainUplad {
        width: 100%;
        margin-right: 20px;
        @include smallmobile {
          margin-right: unset;
          margin-bottom: 20px;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
  .btnWrap {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    button {
      background-color: $pink-color;
      border: none;
      border-radius: 5px;
      color: $White-color;
      padding: 10px;
    }
  }
}
