@import "../fonts/font.scss";
// fonts
$Poppins-Regular: "Poppins-Regular";
$Poppins-Bold: "Poppins-Bold";
$Poppins-SemiBold: "Poppins-SemiBold";
$Poppins-BoldItalic: "Poppins-BoldItalic";
$Poppins-Italic: "Poppins-Italic";
// color
$primaryBlack-color: #1a1818;
$ofWhite-color: #f9f8fd;
$White-color: #ffffff;
$black-color: #000000;
$pink-color: #df1681;
$darkPink-color: #ac116b;
$gray-color: #707070;
$gray-color-font: #505050;
$footer-color: #4a5264;
$header-color: #f4f6f8;
$fild-border-color: #e9e9e9;
$transparent-color: transparent;
$userListing-color: #595f72;
$input-border-color: #e8e8e8;
$provider-blue-color: #6a80c7;
$blue-color: #3151b5;
$th-color: #777777;
$ListingBox-color: #f4f4f4;
$green-color: #4dcd76;
$light-gray: #f6f5f6;
$line-color: #dbdbdb;
$div-bg-color: #f3f3f3;

// media q
$xxs-min-width: 425px;
$xxxs-max-width: 475px;
$xxs-max-width: 576px;
$xs-min-width: 577px;
$xs-max-width: 768px;
//max-width changed from 767 to 768 for about section
$sm-min-width: 768px;
$sm-max-width: 991px;
$md-min-width: 992px;
$mdd-min-width: 1025px;
$mdd-max-width: 1024px;
$md-max-width: 1199px;
$lg-min-width: 1200px;
$lg-max-width: 1440px;
$xlg-min-width: 1441px;
$xxlg-min-width: 1800px;
$xxlg-max-width: 1920px;
$xlg-max-width: 1081px;
