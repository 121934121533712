@import "../../../App.scss";
.viewOrgSection {
  input,
  textarea {
    border: $input-border-color;
    outline: none;
    padding: 10px 10px;
    border-radius: 5px;
  }
  .createdAt {
    border: 1px solid $black-color;
  }
  textarea {
    resize: none;
    height: 150px;
  }
  label {
    margin: 15px 0;
    font-weight: 700;
  }
  .uploadPicWrapper {
    display: flex;
    gap: 12px;

    .imageprview {
      .preview {
        position: relative;
        @include flex;
        width: 200px;
        height: 200px;
        background-color: #fff;
        border-radius: 5px;
        .innerpreview {
          img {
            object-fit: fill;
            width: 100%;
            height: 100%;
          }
          p {
            font-weight: 700;
          }
          border-radius: 5px;
          @include flex;
          width: 190px;
          height: 190px;
          background-color: $div-bg-color;
        }
      }
      .bannerPreview {
        width: 400px !important;
      }
      .bannerInner {
        width: 390px !important;
      }
      .cross {
        position: absolute;
        top: 12px;
        right: 12px;
        svg {
          color: $White-color;
        }
      }
    }

    .upload {
      display: flex;
      gap: 12px;
      align-items: end;
      .uploadImg {
        @include flex;
        width: 50px;
        height: 50px;
        background: #fff;
        border-radius: 5px;
        padding: 12px;
        img {
          width: 24px;
        }
      }
    }
  }
  .inputsWrapper {
    display: flex;
    flex-direction: column;
  }
  .btnwrapper {
    display: flex;
    gap: 12px;
    justify-content: end;
    margin-top: 30px;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 8px 18px;
      border: none;
      color: #fff;
      border-radius: 4px;
      img {
        // margin-right: 8px;
      }
    }

    .btncancel {
      background-color: #595f72;
    }

    .btnadd {
      background-color: #777777;
    }
  }
}
