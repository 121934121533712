@import "./variable.scss";
/*mixins declared below*/

@mixin xxsMobile {
  @media (max-width: #{$xxs-min-width}) {
    @content;
  }
}
@mixin smMobile {
  @media (max-width: #{$xs-min-width}) {
    @content;
  }
}
@mixin midmobile {
  @media (max-width: #{$xxs-max-width}) {
    @content;
  }
}
@mixin smallmobile {
  @media (max-width: #{$xxxs-max-width}) {
    @content;
  }
}
// upTo 767px breakpoint
@mixin mobile {
  @media (max-width: #{$xs-max-width}) {
    @content;
  }
}
//laptop screen 1024
@mixin laptopscreen {
  @media (max-width: #{$mdd-max-width}) {
    @content;
  }
}
// tablet view 991
@mixin tablet {
  @media (max-width: #{$sm-max-width}) {
    @content;
  }
}
//max-1440px
@mixin midlarge {
  @media (max-width:#{$lg-max-width}) {
    @content;
  }
}

// from 767px - 1200px
@mixin tabletNew {
  @media (min-width: #{$sm-min-width}) and (max-width:#{$md-max-width}) {
    @content;
  }
}

@mixin desktopMed {
  @media (min-width: #{$lg-min-width}) and (max-width:#{$lg-max-width}) {
    @content;
  }
}
@mixin desktopsmall {
  @media (max-width: #{$md-max-width}) {
    @content;
  }
}
// 1200px
@mixin desktop {
  @media (min-width: #{$lg-min-width}) {
    @content;
  }
}
// 1440px breakpoint
@mixin desktoplarge {
  @media (min-width: #{$xlg-min-width}) and (max-width:#{$xxlg-max-width}) {
    @content;
  }
}

//1440px and above
@mixin desktoplargescreen {
  @media (min-width: #{$xlg-min-width}) {
    @content;
  }
}

// extra large desktops
@mixin desktopxtralarge {
  @media (min-width: #{$xxlg-min-width}) {
    @content;
  }
}
// extra extra large desktops 1920
@mixin desktopxxtralarge {
  @media (min-width: #{$xxlg-max-width}) {
    @content;
  }
}
//largee screen
@mixin largescreen {
  @media (max-width: #{$xlg-max-width}) {
    @content;
  }
}

//***** all the font sizes *****//
// font mixin creation
@mixin flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin error {
  text-align: left;
  color: red;
  font-size: 12px;
}
