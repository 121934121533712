@import "../../../App.scss";
.EditEmailMain {
  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: 400px;
    margin: 0;
    margin-bottom: 15px;
  }
  
  .EditEmailWrap {
    .InputWrap {
      margin-top: 15px;
      
      .to {
        color: #df1681;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 3px;
        margin: 0;
      }
      input {
        width: 100%;
        border: 1px solid #e8e8e8;
        border-radius: 10px;
        padding: 5px 10px;
      }
      input:focus {
        outline: none;
      }
      select {
        margin-top: 10px;
        margin-bottom: 20px;
        border: none;
        width: 100%;
        border-bottom: 1px solid $input-border-color;
      }
      select:focus {
        outline: none;
      }
    }

    .btnWrap {
      margin-top: 15px;
      display: flex;
      gap: 1rem;
      button {
        background-color: $pink-color;
        border: none;
        border-radius: 5px;
        color: $White-color;
        padding: 10px;
      }
      .cancelBtn {
        background-color: $White-color;
        color: #df1681;
        border: 1px solid #df1681;
        padding: 10px;
        border-radius: 5px;
      }
    }
  }
}

.uploadPicWrapper {
    display: flex !important;
    gap: 12px !important;

    .imageprview {
      .preview {
        position: relative;
        @include flex;
        width: 200px;
        height: 200px;
        background-color: #fff;
        border-radius: 5px;
        .innerpreview {
          p {
            font-weight: 700;
          }
          border-radius: 5px;
          @include flex;
          width: 190px;
          height: 190px;
          background-color: $div-bg-color;
        }
      }
      .cross {
        position: absolute;
        top: 12px;
        right: 12px;

        img {
          width: 23px;
          color: #fff;
        }
      }
    }

    .upload {
      display: flex;
      align-items: center;
      .uploadImg {
        @include flex;
        width: 50px;
        height: 50px;
        background: #fff;
        border-radius: 5px;
        padding: 12px;
        img {
          width: 24px;
        }
      }
    }
  }
