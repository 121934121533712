@import "../../../App.scss";
.branchesFormSection {
  padding: 10px 20px;

  .error {
    color: red;
  }
  .inputsWrapper {
    display: flex;
    flex-direction: column;

    .customDropDown {
      max-height: 300px;
      overflow: auto;
      background-color: #fff;
      border-radius: 12px;

      .selectedBorder {
        background-color: $pink-color;
        width: 100%;
        border-radius: 5px;
        color: $White-color;
        padding: 5px;
      }
      .disabledBorder {
        background-color: $line-color;
        width: 100%;
        border-radius: 5px;
        padding: 5px;
      }

      .dropdownBar {
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
      }

      ul {
        color: #595f72;
        padding: 0 15px;
        li {
          list-style: none;
          img {
            margin-right: 12px;
          }
        }
      }
    }
    .Text {
      display: flex;
      flex-direction: column;
    }

    input,
    select {
      border: $input-border-color;
      outline: none;
      padding: 10px 10px;

      border-radius: 5px;
    }
    label {
      margin: 15px 0;
      font-weight: 700;
    }
  }
  .btnwrapper {
    margin-top: 30px;
    display: flex;
    justify-content: end;
    margin-left: 20px;
    .btnWrapperShift {
      display: flex;
      gap: 12px;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 8px 18px;
        border: none;
        color: #fff;
        border-radius: 4px;
      }
    }

    .btncancel {
      background-color: #777777;
    }
    .btnadd {
      width: 100%;
      background-color: #595f72;
    }
  }
}
