@import "../../../App.scss";

@import "../../../App.scss";

.menuButtonFormSection {
  padding: 10px 20px;

  h3 {
    margin-bottom: 20px;
  }

  .error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }

  .formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    label {
      margin: 15px 0;
      font-weight: 700;
    }

    input,
    select,
    textarea {
      border: $input-border-color;
      outline: none;
      padding: 10px 10px;
      border-radius: 5px;
    }

    textarea {
      resize: vertical;
      min-height: 100px;
    }

    .imageUpload {
      input[type="file"] {
        border: $input-border-color;
        padding: 8px;
        width: 100%;
        border-radius: 5px;
      }

      .imagePreview {
        margin-top: 10px;
        
        img {
          max-width: 100px;
          height: auto;
          border-radius: 5px;
          border: 1px solid $input-border-color;
        }
      }
    }
  }

  .checkboxGroup {
    display: flex;
    align-items: center;
    margin: 15px 0;

    input[type="checkbox"] {
      margin-right: 10px;
      width: 16px;
      height: 16px;
    }

    label {
      margin: 0;
      font-weight: 700;
    }

    .statusText {
      margin-left: 10px;
      color: #595f72;
    }
  }

  .btnWrapper {
    width: 100%;
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    margin-top: 30px;
    margin-right: 30px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 8px 18px;
      border: none;
      color: #fff;
      border-radius: 4px;
      cursor: pointer;
    }

    .btnCancel {
      background-color: #777777;
      &:hover {
        background-color: darken(#777777, 5%);
      }
    }

    .btnAdd {
      background-color: #595f72;
      &:hover {
        background-color: darken(#595f72, 5%);
      }
    }
  }
}