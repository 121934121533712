@import "../../App.scss";

.fildBox {
  display: flex;
  justify-content: end;
  margin-top: 18px;
  height: 38px;

  button {
    background-color: $footer-color;
    border: 0;
    color: $White-color;
    border-radius: 5px;
    padding: 5px 15px;
  }
}

.buttonMain {
  position: relative;
  height: calc(100vh - 190px);
  width: 100%;
  overflow-x: auto;

  .buttonSection {
    width: 100%;
    height: calc(100% - 87px);
    padding: 20px 40px;
    overflow-x: auto;

    table {
      width: 100%;
      border-collapse: collapse;
      overflow-x: auto;

      @media screen and (max-width: 1024px) {
        width: 950px;
      }
    }

    tr {
      th {
        padding: 20px 10px;
        color: #4a4a4a;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 2px solid #e6eaf0;
        text-align: left;
      }

      td {
        padding: 20px 10px;
        color: #4a4a4a;
        font-size: 14px;
        font-weight: 400;
        border-bottom: 2px solid #e6eaf0;
        vertical-align: middle;

        .buttonIcon {
          font-size: 18px;
          color: #666;
        }

        .status {
          padding: 6px 12px;
          border-radius: 20px;
          font-size: 12px;
          font-weight: 500;

          &.active {
            background-color: #e8f5e9;
            color: #2e7d32;
          }

          &.inactive {
            background-color: #ffebee;
            color: #c62828;
          }
        }

        .menuType {
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 12px;
          font-weight: 500;

          &.main {
            background-color: #e3f2fd;
            color: #1976d2;
          }

          &.sub {
            background-color: #f3e5f5;
            color: #7b1fa2;
          }
        }
      }

      .userEditor {
        width: 0%;

        border: none;
        cursor: pointer;
        position: relative;
        text-align: center;
        padding: 0;

        .EditorWrap {
          position: absolute;
          right: 0;
          text-align: left;
          margin-top: 10px;
          background-color: $White-color;
          width: 260px;
          filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.16));
          z-index: 1;
          display: block;
          padding: 20px;
          border-radius: 5px;

          ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
              cursor: pointer;
              font-size: 14px;
              font-weight: 400;
              border-bottom: 1px solid $input-border-color;
              padding: 5px 0px;
              color: $userListing-color;
            }

            li:last-child {
              cursor: pointer;
              border: none;
            }
          }
        }
      }

      .loaderWrap {
        text-align: center;
        border: unset;
        height: 50vh;
      }
    }
  }
}