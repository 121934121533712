@import "../../App.scss";
.main {
  height: 100vh;
  position: relative;
  .menuItemWrapper {
    background: $userListing-color;
    .menuItemContainer {
      width: max-content;
      padding: 25px 0px 0px 50px;
      .menuItems {
        display: flex;
        flex-direction: row;
        border-bottom: 2px solid $pink-color;
        cursor: pointer;
        .menuItem {
          font-weight: bold;
          background: $White-color;
          padding: 10px 15px;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          color: $black-color;
          margin-right: 10px;
        }
        div:last-child {
          margin-right: 0px;
        }
        .selectedMenuItem {
          font-weight: 500;
          background: $pink-color;
          padding: 10px 15px;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          color: $White-color;
          margin-right: 10px;
        }
      }
    }
  }
}
