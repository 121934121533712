@import "../../../App.scss";
.logFormSection {
  padding: 0 20px;
  .uploadpdflabel,
  .viewlabel {
    padding: 12px 0;
    font-weight: 600;
  }
  .errorText {
    font-weight: 500 !important;
    color: red !important;
    font-size: 12px !important;
  }
  .uploadfilewrapper {
    background-color: #fff;
    width: fit-content;
    display: flex;
    padding: 12px;
    border-radius: 5px;
    img {
      width: 20px;
    }
  }
  .icon {
    width: fit-content;
    cursor: pointer;
    padding-left: 5px;
    svg {
      font-size: 20px;
    }
  }

  .viewpdfwrapper {
    position: relative;
    @include flex;
    background-color: #fff;
    width: 100%;
    height: 300px;
    border-radius: 5px;
    .innerviewpdf {
      @include flex;
      border-radius: 5px;
      background: $div-bg-color;
      width: 95%;
      height: 282px;
      p {
        font-weight: 600;
      }
    }
    .viewpdflogo {
      @include flex;
      width: 40px;
      height: 40px;
      position: absolute;
      padding: 12px 12px;
      background: #fff;
      border-radius: 5px;
      bottom: 20px;
      right: 15px;
      width: fit-content;
      img {
        width: 20px;
      }
    }
  }

  .inputsWrapper {
    display: flex;
    flex-direction: column;
    .Disabled {
      border: 1px solid $black-color;
      border-radius: 5px;
      background-color: #fff;
    }
    input,
    select,
    textarea {
      border: $input-border-color;
      outline: none;
      padding: 10px 10px;
      border-radius: 5px;
    }
    label {
      margin: 15px 0;
      font-weight: 700;
    }
    textarea {
      border: $input-border-color;
    }
    textarea:focus {
      outline: none;
    }
  }
  .btnwrapper {
    display: flex;
    gap: 12px;
    justify-content: end;
    margin-top: 30px;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 8px 18px;
      border: none;
      color: #fff;
      border-radius: 4px;
    }

    .btncancel {
      background-color: #595f72;
    }

    .btnadd {
      background-color: #777777;
    }
  }
}
